import * as React from "react"
import { MdEdit } from "react-icons/md"
import {
  Text,
  Card,
  CardHeader,
  CardSection,
  ThemeCss,
  VisuallyHidden,
  Button,
} from "gatsby-interface"
import {
  OrgSettingsSection,
  OrgSettingsSectionLabel,
} from "@modules/organization/Settings/constants"
import { useOrganizationPlanFieldsFragment } from "@modules/billing/shared/fragments.generated"
import { paymentInformation as text } from "@modules/locales/default.js"
import { OrganizationPermissions } from "@modules/organization/permissions"
import { UpdatePaymentForm } from "@modules/billing/components/UpdatePaymentForm"
import { CreditCard } from "@modules/graphql/types"

export type UpdateResponse = {
  success: boolean
  creditCard: CreditCard
}

export type PaymentMethodProps = {
  organizationId: string
}

export function PaymentMethod({ organizationId }: PaymentMethodProps) {
  const [isEditMode, setIsEditMode] = React.useState(false)

  const { data } = useOrganizationPlanFieldsFragment(organizationId)

  const planId = data?.billing?.plan?.id
  const cardBrand = data?.billing?.creditCard?.brand
  const cardNumber = data?.billing?.creditCard?.lastFour

  return (
    <Card id={OrgSettingsSection.PaymentMethod}>
      <CardHeader
        title={OrgSettingsSectionLabel[OrgSettingsSection.PaymentMethod]}
      >
        {!isEditMode && (
          <OrganizationPermissions
            id={organizationId}
            resource="billing"
            action="edit"
          >
            <Button
              onClick={() => setIsEditMode(true)}
              rightIcon={<MdEdit />}
              variant="GHOST"
            >
              {text.actions.updatePayment}
            </Button>
          </OrganizationPermissions>
        )}
      </CardHeader>
      <CardSection applyPaddingTop={false}>
        {isEditMode ? (
          <UpdatePaymentForm
            organizationId={organizationId}
            id={planId}
            cancelCallback={() => setIsEditMode(false)}
          />
        ) : (
          <Text size="S" noMarginBottom={true}>
            {cardBrand && cardNumber ? (
              <React.Fragment>
                <VisuallyHidden>{text.labels.cardNumber}:</VisuallyHidden>
                <span css={labelCss}>{cardBrand}</span>
                {`**** **** **** ${cardNumber}`}
              </React.Fragment>
            ) : (
              `Credit Card information not found.`
            )}
          </Text>
        )}
      </CardSection>
    </Card>
  )
}

/* styles */

const labelCss: ThemeCss = theme => ({
  marginRight: theme.space[8],
})
