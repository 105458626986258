import * as React from "react"
import useInstallationWindow from "@modules/toolkit/closable-window/useInstallationWindow"
import {
  Card,
  CardHeader,
  CardSection,
  Button,
  Text,
  Spacer,
  ThemeCss,
  Badge,
} from "gatsby-interface"
import { OrgSettingsSection, OrgSettingsSectionLabel } from "../constants"
import { SourceControlProviderIcon } from "@modules/sourceControlProvider"
import { useVcsHealthChecksForWorkspaceQuery } from "../queries.generated"
import { processSignupAttempt } from "@modules/auth/utils"
import { getUserId } from "@modules/analytics/utils"
import {
  VcsHealthCheck,
  VcsHealthCheckStatus,
  SourceControlProvider,
} from "@modules/graphql/types"
import { vcsHealth as text } from "@modules/locales/default.js"
import { FormattedMessage, commonMessageTags } from "@modules/locales"
import Loading from "@modules/ui/components/Loading"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"

export type VCSHealthSectionProps = {
  workspaceId: string
  isOwner: boolean
}

type VCSHealthCheckRowProps = {
  data: VcsHealthCheck
  workspaceId: string
  isOwner: boolean
  refetch: () => void
}

function VCSHealthCheckRow({
  data,
  workspaceId,
  isOwner,
  refetch,
}: VCSHealthCheckRowProps) {
  const { id, source, name, vcsConfigId, status } = data
  const { popInstallationWindow } = useInstallationWindow(() => {
    refetch()
  })

  const isConnected = status === VcsHealthCheckStatus.Connected

  return (
    <li>
      <div css={iconCss} role="presentation">
        {SourceControlProviderIcon({
          sourceControlProvider: source,
        })}
      </div>

      <div css={bodyCss}>
        <strong>{name}</strong>

        <Spacer direction="horizontal" size={4} />

        <Badge tone={isConnected ? `SUCCESS` : `DANGER`}>
          {isConnected ? text.labels.connected : text.labels.failed}
        </Badge>

        {!isConnected && (
          <div css={infoCss}>
            <Text size="S" noMarginBottom={true}>
              {isOwner ? (
                <FormattedMessage<"name" | "source", "strong">
                  message={text.messages.vcsOrgDisconnectedOwnerVersion}
                  tags={commonMessageTags}
                  values={{
                    name: name || ``,
                    source: source,
                  }}
                />
              ) : (
                <FormattedMessage<"source", "strong">
                  message={text.messages.vcsOrgDisconnected}
                  tags={commonMessageTags}
                  values={{
                    source: source,
                  }}
                />
              )}
            </Text>

            {isOwner && (
              <Button
                size="M"
                onClick={() => {
                  if (source === SourceControlProvider.Github) {
                    return popInstallationWindow()
                  }

                  processSignupAttempt(
                    // @ts-ignore
                    source,
                    {
                      csrfToken: getUserId(),
                      reconnectWorkspaceId: workspaceId,
                      errorRedirectUrl: window.location.pathname,
                      loginRedirectUrl: window.location.pathname,
                      ...(vcsConfigId && {
                        enterpriseVCSConfigId: vcsConfigId,
                      }),
                    },
                    {}
                  )
                }}
              >
                {text.labels.refresh}
              </Button>
            )}
          </div>
        )}
      </div>
    </li>
  )
}

export function VCSHealthSection({
  workspaceId,
  isOwner,
}: VCSHealthSectionProps) {
  const { data, loading, refetch, error } = useVcsHealthChecksForWorkspaceQuery(
    {
      variables: { workspaceId },
      pollInterval: 30000,
      fetchPolicy: `network-only`,
    }
  )

  if (loading) {
    return null
  }

  return (
    <Card id={OrgSettingsSection.VCSHealth}>
      <CardHeader
        title={OrgSettingsSectionLabel[OrgSettingsSection.VCSHealth]}
      />
      <CardSection applyPaddingTop={false}>
        {error && <ErrorAlert>{error.message}</ErrorAlert>}

        {loading && <Loading delay={1000} variant="baby" />}

        {!loading && (
          <ul css={listCss}>
            {data?.vcsHealthChecksForWorkspace?.map(data => (
              <VCSHealthCheckRow
                key={data.id}
                data={data}
                isOwner={isOwner}
                workspaceId={workspaceId}
                refetch={refetch}
              />
            ))}
          </ul>
        )}
      </CardSection>
    </Card>
  )
}

/* styles */

const listCss: ThemeCss = theme => ({
  listStyle: `none`,
  margin: 0,
  display: `grid`,
  gap: theme.space[4],

  "& > li": {
    display: `grid`,
    gap: theme.space[3],
    gridTemplateColumns: `min-content 1fr`,
    margin: 0,

    "&:not(:last-of-type)": {
      borderBottom: `1px solid ${theme.colors.standardLine}`,
      paddingBottom: theme.space[4],
    },
  },
})

const bodyCss: ThemeCss = _theme => ({
  display: `flex`,
  flexWrap: `wrap`,
  alignItems: `baseline`,
})

const iconCss: ThemeCss = theme => ({
  marginTop: theme.space[1],
})

const infoCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[5],
  alignItems: `start`,
  justifyItems: `start`,
  width: `100%`,
  marginTop: theme.space[2],

  [theme.mediaQueries.phablet]: {
    gridTemplateColumns: `1fr min-content`,
    columnGap: `10%`,
  },
})
