import * as React from "react"
import { Notification, Link } from "gatsby-interface"
import { MdWarning } from "react-icons/md"
import { MachinePricingTier } from "@modules/graphql/types"

export type PlatformLimitOverageNotificationProps = {
  message: React.ReactNode
  tier?: MachinePricingTier | null
  link: string
  orgName?: string
}

export function PlatformLimitOverageNotification({
  message,
  tier,
  link,
  orgName,
}: PlatformLimitOverageNotificationProps) {
  const beginning = orgName ? (
    <React.Fragment>
      The <strong>{orgName}</strong> workspace
    </React.Fragment>
  ) : (
    `This site belongs to a workspace, which `
  )
  const isFree = tier === MachinePricingTier.Free

  return (
    <Notification
      Icon={MdWarning}
      variant="SECONDARY"
      tone="WARNING"
      content={
        isFree ? (
          <div>
            {beginning} is using {message} allocation.{" "}
            <Link variant="SIMPLE" to={link}>
              {`Upgrade your plan`}
            </Link>{" "}
            to our Professional tier for as low as $510/year.
          </div>
        ) : (
          <div>
            {beginning} is using {message} allocation. No need to worry! Your
            site will remain intact and you will not be receiving a new bill
            from us. However,{" "}
            <Link
              variant="SIMPLE"
              to={`https://www.gatsbyjs.com/contact-sales/`}
            >
              {`please schedule a call`}
            </Link>{" "}
            with our team to discuss your options.
          </div>
        )
      }
    />
  )
}
