import * as React from "react"
import { Heading, HeadingProps, ThemeCss } from "gatsby-interface"

export function Title({
  as = `h1`,
  fontVariant = `UI`,
  ...rest
}: HeadingProps) {
  return <Heading as={as} fontVariant={fontVariant} css={titleCss} {...rest} />
}

export function Eyebrow({
  as = `span`,
  fontVariant = `UI`,
  variant = `LIGHT`,
  ...rest
}: HeadingProps) {
  return (
    <Heading
      as={as}
      fontVariant={fontVariant}
      variant={variant}
      css={eyebrowCss}
      {...rest}
    />
  )
}
/* styles */

const eyebrowCss: ThemeCss = theme => ({
  display: `block`,
  color: theme.colors.grey[50],
  fontSize: theme.fontSizes[0],
  fontWeight: theme.fontWeights.body,
  marginBottom: theme.space[1],
})

const titleCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[5],
  lineHeight: theme.lineHeights.dense,

  [theme.mediaQueries.tablet]: {
    whiteSpace: `nowrap`,
  },
})
