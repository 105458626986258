import React from "react"
import PropTypes from "prop-types"
import { MdSearch, MdAutorenew } from "react-icons/md"
import { StyledInput } from "gatsby-interface"
import { keyframes } from "@emotion/core"

const filterInputCss = theme => ({
  width: `100%`,
  padding: `0 ${theme.space[4]} 0 ${theme.space[8]}`,
  flexGrow: 1,
  zIndex: 0,
})

const iconBoxCss = theme => ({
  position: `absolute`,
  top: 0,
  left: 0,
  bottom: 0,
  width: theme.space[8],
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  color: theme.colors.grey[40],
  fontSize: theme.fontSizes[3],
  zIndex: 2,
})

const rotate = keyframes`
  to {
    transform:rotate(359deg);
  }
`

const searchingCss = theme => ({
  color: theme.colors.grey[50],
  animation: `${rotate} 1s ease infinite`,
})

function Searchbar({
  ariaLabel = `Search bar`,
  placeholder = `Enter search term`,
  value,
  onChange,
  onBlur,
  className,
  searching = false,
}) {
  return (
    <div className={className} css={{ position: `relative`, width: `100%` }}>
      <div css={iconBoxCss} aria-hidden>
        {searching ? <MdAutorenew css={searchingCss} /> : <MdSearch />}
      </div>
      <StyledInput
        css={filterInputCss}
        aria-label={ariaLabel}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
    </div>
  )
}

Searchbar.propTypes = {
  ariaLabel: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  className: PropTypes.string,
}

export default Searchbar
