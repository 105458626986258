import * as React from "react"
import { ThemeCss, Theme, ProgressBar } from "gatsby-interface"
import { useWorkspaceFeaturesUsage } from "@modules/organization/shared/hooks/useWorkspaceFeaturesUsage"

export type LimitUsage = {
  label: string
  limit: string
  usage: string
  percentageUsage: number
}

export type OrganizationLimitsWidgetsProps = {
  organizationId: string
}

export function OrganizationLimitsWidgets({
  organizationId,
  ...rest
}: OrganizationLimitsWidgetsProps) {
  const usage = useWorkspaceFeaturesUsage(organizationId, {
    renewableOnly: true,
  })

  if (usage.metrics?.length && usage.metrics.length < 1) {
    return null
  }

  return (
    <div css={theme => rootCss(theme, usage.metrics.length)} {...rest}>
      {usage.metrics.map(
        ({ label, limit, usage, percentage, isRecalculating }, idx) => {
          return (
            <div css={itemCss} key={label}>
              <span css={labelCss}>{label}</span>
              <div css={theme => graphCss(theme, isRecalculating)}>
                <ProgressBar
                  value={percentage || 0}
                  max={100}
                  aria-describedby={label}
                  height={8}
                  getProgressColor={getProgressColor}
                />
              </div>
              <span id={label} css={theme => valueCss(theme, isRecalculating)}>
                {isRecalculating ? `Updating ...` : `${usage} / ${limit}`}
              </span>
            </div>
          )
        }
      )}
    </div>
  )
}

/* utils */

function getProgressColor(theme: Theme, progression: number) {
  if (progression >= 75) {
    return theme.colors.orange[60]
  }

  return theme.colors.purple[60]
}

/* styles */

const rootCss = (theme: Theme, numOfCols: number) => ({
  display: `grid`,
  columnGap: theme.space[10],
  rowGap: theme.space[6],
  gridTemplateColumns: `repeat(2, 1fr)`,

  [theme.mediaQueries.phablet]: {
    gridTemplateColumns: `repeat(${numOfCols}, 1fr)`,
  },

  [theme.mediaQueries.desktop]: {
    columnGap: theme.space[6],
  },
})

const itemCss: ThemeCss = theme => ({
  alignContent: `start`,
  display: `grid`,
  gap: theme.space[2],
})

const labelCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.grey[50],
  fontWeight: theme.fontWeights.body,

  "&:first-letter": {
    textTransform: `uppercase`,
  },
})

const valueCss = (theme: Theme, isRenewable = false) => [
  {
    fontSize: theme.fontSizes[0],
    color: theme.colors.grey[50],
  },
  isRenewable && {
    color: theme.colors.grey[50],
    fontStyle: `italic`,
    letterSpacing: theme.letterSpacings.tracked,
  },
]

const graphCss = (theme: Theme, isRenewable = false) => [
  {
    [theme.mediaQueries.desktop]: {
      width: `100px`,
    },
  },
  isRenewable && {
    color: theme.colors.grey[50],
    fontStyle: `italic`,
  },
]
