import * as React from "react"
import {
  useShowSuccessToast,
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
} from "gatsby-interface"
import { selfHostedGitConnection as text } from "@modules/locales/default.js"
import { useDeleteVcsConfigurationMutation } from "@modules/organization/mutations.generated"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { DeleteGitConnectionForm } from "./DeleteGitConnectionForm"
import { OnPremisesGitConnectionsDocument } from "@modules/organization/queries.generated"

export type DeleteGitConnectionModalProps = {
  closeModal: () => void
  vcsConfigId: string
  connectionName: string
  organizationId: string
}

export function DeleteGitConnectionModal({
  closeModal,
  vcsConfigId,
  connectionName,
  organizationId,
}: DeleteGitConnectionModalProps) {
  const [deleteVcsConfigurationMutation] = useDeleteVcsConfigurationMutation()
  const [setError, errorAlert] = useTriggerErrorAlert()
  const showSuccessToast = useShowSuccessToast()

  return (
    <StyledModal variant="WARNING">
      <StyledModalHeader onCloseButtonClick={closeModal}>
        {text.messages.deleteGitConnectionConf}{" "}
        <strong
          css={theme => ({
            color: theme.colors.red[60],
          })}
        >
          {connectionName}?
        </strong>
      </StyledModalHeader>
      <StyledModalBody>
        {errorAlert}
        <DeleteGitConnectionForm
          submitCallback={() => {
            setError(null)

            deleteVcsConfigurationMutation({
              variables: {
                id: vcsConfigId,
              },
              refetchQueries: [
                {
                  query: OnPremisesGitConnectionsDocument,
                  variables: {
                    workspaceId: organizationId,
                  },
                },
              ],
            })
              .then(({ data }) => {
                if (data?.deleteVcsConfiguration?.success) {
                  closeModal()
                  showSuccessToast(
                    <React.Fragment>
                      <strong>{connectionName} </strong>{" "}
                      {text.messages.deletedSuccessfully}
                    </React.Fragment>
                  )
                }
              })
              .catch(err => setError(err))
          }}
          cancelCallback={closeModal}
          connectionName={connectionName}
        />
      </StyledModalBody>
    </StyledModal>
  )
}
