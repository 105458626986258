import { usePlatformLimitSummariesQuery } from "@modules/organization/queries.generated"
import { WatchQueryFetchPolicy } from "apollo-client"

export type Options = {
  skip?: boolean
  fetchPolicy?: WatchQueryFetchPolicy
}

export function usePlatformLimitSummariesForUsageValues(
  id: string,
  { skip = false, fetchPolicy = `cache-only` }: Options = {}
) {
  const { data, loading, error } = usePlatformLimitSummariesQuery({
    variables: { workspaceId: id },
    fetchPolicy: fetchPolicy,
    skip: skip,
  })

  const raw = data?.platformLimitSummaries || []
  const reshaped = raw
    .filter(Boolean)
    .reduce<Record<string, number>>((acc, cur) => {
      acc[cur.name] = cur.workspace?.count

      return acc
    }, {})

  return [reshaped, { loading, error }] as const
}
