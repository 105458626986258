import * as React from "react"
import { Contributor } from "@modules/graphql/types"
import { Member } from "./Member"
import { ThemeCss } from "gatsby-interface"

export type MembersListProps = {
  members: Contributor[]
  organizationId: string
  permissionToRead: boolean
  permissionToDelete: boolean
  permissionToInvite: boolean
  recalculateUsage: () => void
}

export function MembersList({
  members,
  organizationId,
  permissionToRead,
  permissionToDelete,
  permissionToInvite,
  recalculateUsage,
}: MembersListProps) {
  return (
    <div css={listCss}>
      {members.map(member => (
        <Member
          key={member.id}
          member={member}
          organizationId={organizationId}
          hasPermissionToRead={permissionToRead}
          hasPermissionToDelete={permissionToDelete}
          hasPermissionToInvite={permissionToInvite}
          recalculateUsage={recalculateUsage}
        />
      ))}
    </div>
  )
}
/* styles */

const listCss: ThemeCss = theme => [
  {
    display: `grid`,
    flexDirection: `column`,
    gap: theme.space[4],
    margin: 0,
  },
]
