import * as React from "react"
import { useWorkspaceMembersSimplifiedQuery } from "../queries.generated"
import * as merge from "deepmerge"
import { NetworkStatus } from "apollo-client"

export const LIMIT = Number(process.env.GATSBY_WORKSPACE_MEMBERS_LIMIT) || 100

export const useWorkspaceMembers = (
  workspaceId: string,
  setError: ({ message }: { message: string }) => void,
  skip = false
) => {
  const [noLoadMoreResult, setNoLoadMoreResult] = React.useState(false)

  const { data, loading, error, fetchMore, networkStatus } =
    useWorkspaceMembersSimplifiedQuery({
      variables: {
        workspaceId,
        limit: LIMIT,
        offset: 0,
      },
      fetchPolicy: `cache-and-network`,
      onError: e => setError(e),
      skip: skip,
    })

  const loadMore = () => {
    const allItems = data?.workspaceMembersSimplified?.count ?? 0
    const loadedItems =
      (data?.workspaceMembersSimplified?.members &&
        data.workspaceMembersSimplified.members.length) ??
      0
    const isMore = allItems > loadedItems && loadedItems >= LIMIT
    const isFetching = networkStatus === NetworkStatus.fetchMore

    if (noLoadMoreResult || !isMore || isFetching) {
      return
    }

    fetchMore({
      variables: {
        workspaceId,
        limit: LIMIT,
        offset: loadedItems,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        // sometimes backend provides un sync numbers for `count` and `members.length`
        // what causes infinite loop of triggering 'loadMore', noLoadMoreResult flag breaks the loop
        if (
          fetchMoreResult?.workspaceMembersSimplified?.members?.length === 0
        ) {
          setNoLoadMoreResult(true)
        }

        return fetchMoreResult
          ? merge(previousResult, fetchMoreResult)
          : previousResult
      },
    })
  }

  return [
    data?.workspaceMembersSimplified?.members,
    {
      loading: loading && !data,
      loadingMore: Boolean(loading && data),
      error: error,
      loadMore,
    },
  ] as const
}
