// THIS IS A GENERATED FILE
import * as Types from '@gatsbyjs/graphql/types';

import gql from 'graphql-tag';
import { useApolloFragment } from 'apollo-fragment-react';


export type ContributorUpdateFieldsFragment = (
  { __typename?: 'Contributor' }
  & Pick<Types.Contributor, 'id' | 'contributorRole'>
  & { resources?: Types.Maybe<Array<(
    { __typename?: 'CloudResource' }
    & Pick<Types.CloudResource, 'resourceId' | 'resourceType' | 'role' | 'name'>
  )>> }
);

export const ContributorUpdateFieldsFragmentDoc = gql`
    fragment contributorUpdateFields on Contributor {
  id
  contributorRole
  resources {
    resourceId
    resourceType
    role
    name
  }
}
    `;

  /**
   * __useContributorUpdateFieldsFragment__
   * To read a fragment data from Apollo Cache, call `useContributorUpdateFieldsFragment` and pass it the ID of the cached object.
   * When your component renders, `useContributorUpdateFieldsFragment` returns an object from Apollo Client cache that contains data property
   * you can use to render your UI.
   *
   * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
   *
   * @example
   * const { data } = useContributorUpdateFieldsFragment('fragment-id');
   */
export function useContributorUpdateFieldsFragment(id: string) {
          return useApolloFragment<ContributorUpdateFieldsFragment>(ContributorUpdateFieldsFragmentDoc, id);
        }
export type ContributorUpdateFieldsFragmentHookResult = ReturnType<typeof useContributorUpdateFieldsFragment>;