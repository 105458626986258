import React from "react"
import { Notification } from "gatsby-interface"

export default function PlanChangeNotification({
  currentPlanName,
  requestedPlanName,
  onDismiss,
}) {
  const shouldNotifyRequestSent =
    requestedPlanName !== "Free" && currentPlanName !== requestedPlanName
  return (
    <Notification
      tone="SUCCESS"
      content={
        <React.Fragment>
          {shouldNotifyRequestSent
            ? `Successfully sent request to change plan to `
            : `Successfully changed plan to `}
          <strong
            css={theme => ({
              marginLeft: theme.space[2],
              marginRight: theme.space[2],
            })}
          >
            {requestedPlanName !== currentPlanName
              ? requestedPlanName
              : currentPlanName}
            !
          </strong>
          {requestedPlanName === "Free"
            ? "This will take effect at the end of your billing period."
            : null}
        </React.Fragment>
      }
      onDismissButtonClick={onDismiss}
    />
  )
}
