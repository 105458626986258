import * as React from "react"
import { Link, Notification, ThemeCss } from "gatsby-interface"
import { MdArrowForward, MdInfo } from "react-icons/md"
import { useDisabledDataBuildsForSiteNotifications } from "../hooks/useDisabledDataBuildsForSiteNotifications"

const contentCss: ThemeCss = () => ({
  margin: 0,
  maxWidth: "860px",
})

const boldCss: ThemeCss = theme => ({
  fontWeight: theme.fontWeights.bold,
})

const linkIconCss: ThemeCss = theme => ({
  marginLeft: theme.space[1],
})

type DisabledDataBuildsBannerProps = {
  organizationId: string
  siteId: string
  siteName: string
}

export function DisabledDataBuildsBanner({
  siteId,
  organizationId,
  siteName,
}: DisabledDataBuildsBannerProps) {
  const {
    disabledDataBuildsForSiteVisible,
    hideDisabledDataBuildsForSiteNotification,
  } = useDisabledDataBuildsForSiteNotifications(siteId)

  return disabledDataBuildsForSiteVisible ? (
    <Notification
      tone={"DANGER"}
      variant={"SECONDARY"}
      Icon={MdInfo}
      showDismissButton={true}
      onDismissButtonClick={hideDisabledDataBuildsForSiteNotification}
      content={
        <p css={contentCss}>
          Data builds for <span css={boldCss}>{siteName}</span> are suspended
          due to excessive failed content updates.{" "}
          <Link
            variant="SIMPLE"
            href={`/dashboard/${organizationId}/sites/${siteId}/deploys`}
          >
            View errors
            <MdArrowForward css={linkIconCss} />
          </Link>
        </p>
      }
    />
  ) : null
}
