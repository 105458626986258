import * as React from "react"
import { useResendInvitationMutation } from "@modules/organization/mutations.generated"
import {
  useShowSuccessToast,
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledModalActions,
  Button,
  Text,
} from "gatsby-interface"
import { MdSend } from "react-icons/md"
import { ModalMessage } from "@modules/modal"
import {
  manageContributors as text,
  ui as uiText,
} from "@modules/locales/default.js"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { commonMessageTags, FormattedMessage } from "@modules/locales"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { StyledForm } from "@modules/ui/components/Primitives"

export type ResendMemberInviteModalProps = {
  closeModal: () => void
  email: string
  contributorId: string
}

function ResendMemberInviteModal({
  closeModal,
  email,
  contributorId,
}: ResendMemberInviteModalProps) {
  const { trackSegment } = useTracker()
  const showSuccessToast = useShowSuccessToast()
  const [mutate, { loading }] = useResendInvitationMutation()
  const [setError, errorAlert] = useTriggerErrorAlert()

  return (
    <StyledModal variant="RETAKE">
      <StyledModalHeader onCloseButtonClick={closeModal}>
        {text.headers.resendInviteQuestion}
      </StyledModalHeader>

      <StyledModalBody>
        <ModalMessage>
          <Text>
            <FormattedMessage<"email", "strong">
              message={text.messages.resendOrgInviteConf}
              values={{ email }}
              tags={commonMessageTags}
            />
          </Text>
          {errorAlert}
        </ModalMessage>
        <StyledForm
          onSubmit={e => {
            e.preventDefault()
            trackSegment({
              type: SegmentEventType.Track,
              event: `Resent member invite`,
              properties: {
                location: `Members tab - "Resend member invite" modal`,
              },
            })
            mutate({
              variables: {
                id: contributorId,
              },
            })
              .then(() => {
                closeModal()
                showSuccessToast(text.messages.memberInvitationResent)
              })
              .catch(err => setError(err))
          }}
        >
          <StyledModalActions>
            <Button
              variant="SECONDARY"
              tone="NEUTRAL"
              type="button"
              onClick={() => {
                trackSegment({
                  type: SegmentEventType.Track,
                  event: `Cancelled resending member invite`,
                  properties: {
                    location: `Members tab - "Resend member invite" modal`,
                  },
                })
                closeModal()
              }}
            >
              {uiText.actions.cancel}
            </Button>
            <Button
              variant="PRIMARY"
              type="submit"
              rightIcon={<MdSend />}
              loading={loading}
            >
              {text.actions.resendInvite}
            </Button>
          </StyledModalActions>
        </StyledForm>
      </StyledModalBody>
    </StyledModal>
  )
}

export default ResendMemberInviteModal
