import * as React from "react"
import {
  Card,
  CardHeader,
  CardSection,
  Text,
  Button,
  GitHubIcon,
  Badge,
  Modal,
  ModalCard,
  GitLabIcon,
} from "gatsby-interface"
import { selfHostedGitConnection as text } from "@modules/locales/default.js"
import { MdArrowForward, MdCancel } from "react-icons/md"
import { EnterpriseConnectionModal } from "@modules/organization/shared/components/EnterpriseConnectionModal"
import { OrgSettingsSection } from "../constants"
import { useOnPremisesGitConnectionsQuery } from "@modules/organization/queries.generated"
import {
  PublicEnterpriseVcsConfig,
  SourceControl,
  SourceControlProvider,
} from "@modules/graphql/types"
import { useFlags } from "@modules/featureFlags"
import { DeleteGitConnectionModal } from "./DeleteGitConnectionModal"
import { useAvailableSourceProvidersWithVcsConfigQuery } from "@modules/site/create/Import/queries.generated"
import { processSignupAttempt } from "@modules/auth/utils"
import {
  getPathToCreateSite,
  getPathToSelfHostedSettings,
  mapProviderToLoginOption,
} from "@modules/site/create/shared/utils"
import { getUserId } from "@modules/analytics/utils"
import useSelectedProvider from "@modules/site/create/shared/hooks/useSelectedProvider"
import { usePersistentState } from "@modules/toolkit/hooks/usePersistentState"
import { navigate } from "gatsby"

export type SelfHostedGitConnectionProps = {
  organizationId: string
  popConnectionModal?: boolean
  sourceType?: string
}

function ConnectedInstance({
  connection,
  withDelete,
  organizationId,
  sourceProviders,
}: {
  connection: PublicEnterpriseVcsConfig
  withDelete?: boolean
  organizationId: string
  sourceProviders?: SourceControl[]
}) {
  const [isDeleteModalOpened, setIsDeleteModalOpened] = React.useState(false)
  const [, setSelectedProvider] = useSelectedProvider()
  const [, setVcsConfigId] = usePersistentState<string>(
    "gatsby:providerConfigId",
    ``
  )

  const isConnected = Boolean(
    sourceProviders?.find(
      provider => provider?.vcsConfigId === connection.id && provider?.connected
    )
  )
  const isGitlabSelfManaged =
    connection.source === SourceControlProvider.GitlabSelfManaged

  return (
    <div
      css={theme => ({
        display: `flex`,
        justifyContent: `space-between`,
        "&:not(:last-child)": {
          marginBottom: theme.space[5],
        },
      })}
    >
      <div
        css={theme => ({
          display: `flex`,
          alignItems: `center`,
          gap: theme.space[3],
        })}
      >
        {isGitlabSelfManaged ? <GitLabIcon /> : <GitHubIcon />}
        <Text noMarginBottom={true}>{connection.name}</Text>
        {isConnected && (
          <Badge tone="SUCCESS">{text.messages.statusConnected}</Badge>
        )}
      </div>
      <div css={theme => ({ display: `flex`, gap: theme.space[2] })}>
        {isConnected ? (
          <Button
            variant="GHOST"
            onClick={() => {
              setSelectedProvider(connection.source)
              setVcsConfigId(connection.id)
              navigate(getPathToCreateSite(organizationId))
            }}
          >
            Add A Site <MdArrowForward />
          </Button>
        ) : (
          <Button
            variant="SECONDARY"
            tone="SUCCESS"
            onClick={() => {
              if (!connection?.id) {
                return
              }
              processSignupAttempt(
                mapProviderToLoginOption[connection.source],
                {
                  csrfToken: getUserId(),
                  attachProvider: true,
                  errorRedirectUrl: getPathToSelfHostedSettings(organizationId),
                  loginRedirectUrl: getPathToSelfHostedSettings(organizationId),
                  enterpriseVCSConfigId: connection.id,
                },
                {}
              )
            }}
          >
            Authorize Connection
          </Button>
        )}
        {withDelete ? (
          <Button
            variant="GHOST"
            tone="DANGER"
            onClick={() => setIsDeleteModalOpened(true)}
          >
            <MdCancel />
            Delete
          </Button>
        ) : null}
        {isDeleteModalOpened ? (
          <Modal
            isOpen={isDeleteModalOpened}
            aria-label={`Do you want to remove that git connection?`}
            onDismiss={() => setIsDeleteModalOpened(false)}
            type="error"
          >
            <ModalCard>
              <DeleteGitConnectionModal
                closeModal={() => setIsDeleteModalOpened(false)}
                connectionName={connection.name}
                vcsConfigId={connection.id}
                organizationId={organizationId}
              />
            </ModalCard>
          </Modal>
        ) : null}
      </div>
    </div>
  )
}

export function SelfHostedGitConnection({
  organizationId,
  popConnectionModal,
  sourceType,
}: SelfHostedGitConnectionProps) {
  const [source, setSource] = React.useState(
    (sourceType as SourceControlProvider) ||
      SourceControlProvider.GithubEnterprise
  )
  const [isModalOpen, setIsModalOpen] = React.useState(
    popConnectionModal || false
  )

  const { data, loading } = useOnPremisesGitConnectionsQuery({
    variables: { workspaceId: organizationId },
    fetchPolicy: `network-only`, // needs network only because on creation
    // the cache will return a value that is inconsistent with the actual desired values.
  })

  const {
    data: sourceProviders,
  } = useAvailableSourceProvidersWithVcsConfigQuery({
    variables: {
      workspaceId: organizationId,
    },
    fetchPolicy: "network-only",
  })
  const selfHostedProviders = React.useMemo(
    () =>
      sourceProviders?.availableSourceProvidersWithVcsConfig?.filter(
        connection => {
          return Boolean(connection?.vcsConfigId)
        }
      ),
    [sourceProviders]
  )
  const { flags } = useFlags()
  const onPremisesConnections = data?.publicVcsConfigsByWorkspace
  return (
    <React.Fragment>
      <Card id={OrgSettingsSection.SelfHostedGit}>
        <CardHeader
          css={{
            display: `flex`,
            justifyContent: `space-between`,
          }}
          title={text.headers.settings}
        >
          <div>
            <Button
              variant="GHOST"
              onClick={() => {
                if (source !== SourceControlProvider.GithubEnterprise) {
                  setSource(SourceControlProvider.GithubEnterprise)
                }
                setIsModalOpen(true)
              }}
            >
              <GitHubIcon
                css={theme => ({
                  color: theme.colors.black,
                })}
              />{" "}
              Add new
            </Button>
            <Button
              variant="GHOST"
              onClick={() => {
                if (source !== SourceControlProvider.GitlabSelfManaged) {
                  setSource(SourceControlProvider.GitlabSelfManaged)
                }
                setIsModalOpen(true)
              }}
            >
              <GitLabIcon /> Add new
            </Button>
          </div>
        </CardHeader>
        <CardSection applyPaddingTop={false}>
          <Text>{text.messages.settingsDescription}</Text>
          {!loading && (
            <React.Fragment>
              <div css={theme => ({ marginBottom: theme.space[7] })}>
                {onPremisesConnections?.map(connection => (
                  <ConnectedInstance
                    key={connection.id}
                    connection={connection}
                    withDelete={flags.deleteOnPremVcsConnection}
                    organizationId={organizationId}
                    sourceProviders={selfHostedProviders}
                  />
                ))}
              </div>
              <Text size="S">{text.messages.featureDescription}</Text>
            </React.Fragment>
          )}
        </CardSection>
      </Card>
      <EnterpriseConnectionModal
        source={source}
        organizationId={organizationId}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />
    </React.Fragment>
  )
}
