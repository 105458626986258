import * as React from "react"
import { ThemeCss } from "gatsby-interface"

type BannersContainerProps = {
  children?: React.ReactNode
}

export function BannersContainer({ children }: BannersContainerProps) {
  return <section css={containerCss}>{children}</section>
}

/* styles */

const containerCss: ThemeCss = theme => ({
  display: `grid`,
  gridRowGap: theme.space[5],
  paddingTop: theme.space[2],
  paddingBottom: theme.space[5],
})
