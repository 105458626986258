import * as React from "react"
import AddMemberAction from "./AddMemberAction"
import { ThemeCss, Text, Spacer, Theme } from "gatsby-interface"
import { UsageData } from "@modules/organization/shared/hooks/useMembersUsage"

export type MembersActionsProps = {
  organizationId: string
  usage: UsageData
}

export function MembersActions({ organizationId, usage }: MembersActionsProps) {
  return (
    <div css={containerCss}>
      {!usage.isUnlimited && (
        <React.Fragment>
          <Text
            size="S"
            noMarginTop={true}
            noMarginBottom={true}
            css={theme => [usageCss(theme, usage.isRecalculating)]}
          >
            {usage.isRecalculating ? `Updating ...` : usage.message}
          </Text>
          <Spacer size={5} direction="horizontal" />
        </React.Fragment>
      )}
      <AddMemberAction organizationId={organizationId} usage={usage} />
    </div>
  )
}

/* styles */

const containerCss: ThemeCss = theme => ({
  display: `flex`,
  justifyContent: `flex-end`,
  alignItems: `center`,
  paddingBottom: theme.space[6],
})

const usageCss = (theme: Theme, isRecalculating: boolean) => [
  {
    color: theme.colors.grey[60],
  },
  isRecalculating && {
    color: theme.colors.grey[50],
    fontStyle: `italic`,
  },
]
