import * as React from "react"
import { Card, CardHeader, CardSection, Text, Button } from "gatsby-interface"
import {
  OrgSettingsSection,
  OrgSettingsSectionLabel,
} from "@modules/organization/Settings/constants"
import { paymentHistory as text } from "@modules/locales/default.js"
import { useOrganizationPlanFieldsFragment } from "@modules/billing/shared/fragments.generated"

async function createStripeCustomerPortal(stripeCustomerId: string) {
  const stripeRes = await fetch(
    `/api/create-stripe-customer-session?stripeCustomerId=${stripeCustomerId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
  const stripeJson = await stripeRes.json()

  window.open(stripeJson.redirectUrl)
}

export type PaymentHistoryProps = {
  organizationId: string
}

export function PaymentHistory({ organizationId }: PaymentHistoryProps) {
  const { data } = useOrganizationPlanFieldsFragment(organizationId)
  const stripeCustomerId = data?.billing?.stripeCustomerId

  return (
    <Card id={OrgSettingsSection.PaymentHistory}>
      <CardHeader
        title={OrgSettingsSectionLabel[OrgSettingsSection.PaymentHistory]}
      ></CardHeader>
      <CardSection applyPaddingTop={false}>
        <Text size="S">{text.messages.viewYourPaymentHistory}</Text>
        {stripeCustomerId && (
          <Button
            size="M"
            onClick={() => createStripeCustomerPortal(stripeCustomerId)}
          >
            {text.actions.visitPortal}
          </Button>
        )}
      </CardSection>
    </Card>
  )
}
