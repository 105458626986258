import {
  useOrganizationSiteNamesQuery,
  OrganizationRepositoriesQuery,
} from "@modules/organization/queries.generated"
import { mergeOrgDetails } from "../utils/mergeOrgDetails"

const LIMIT = 10 // if we ever want to change the value (+/-) remember to update the hardcoded limit: 10 value in ModifyMemberPanelModal.tsx (reading cachedRepos)

export const useOrganizationSiteNames = (orgId: string, skip = false) => {
  const {
    data,
    loading,
    error,
    fetchMore,
    refetch,
  } = useOrganizationSiteNamesQuery({
    variables: {
      id: orgId,
      limit: LIMIT,
    },
    fetchPolicy: `cache-and-network`,
    skip: skip,
  })

  const hasNextPage =
    data?.organizationDetails?.repositoriesPaged?.pageInfo?.hasNextPage

  const loadMore = () => {
    const actualPageInfo =
      data?.organizationDetails?.repositoriesPaged?.pageInfo

    if (!actualPageInfo?.hasNextPage || !actualPageInfo?.endCursor || loading) {
      return
    }

    fetchMore({
      variables: {
        id: orgId,
        limit: LIMIT,
        nextCursor: actualPageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult
          ? mergeOrgDetails(
              previousResult as OrganizationRepositoriesQuery,
              fetchMoreResult as OrganizationRepositoriesQuery
            )
          : previousResult
      },
    })
  }

  return [
    data?.organizationDetails?.repositoriesPaged,
    data?.organizationDetails?.siteCount,
    {
      loading: loading && !data,
      loadingMore: Boolean(loading && data),
      error,
      refetch,
      loadMore,
      hasNextPage,
    },
  ] as const
}
