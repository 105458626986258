import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { OrganizationPermissions } from "@modules/organization/permissions"
import { PlanInformation } from "@modules/billing/shared/components/PlanInformation"
import { OrganizationStatus } from "@modules/organization/Settings/components/OrganizationStatus"
import usePlanChangeState from "@modules/billing/shared/hooks/usePlanChangeState"
import { OrgSettingsSection } from "../constants"
import { isProduction } from "@modules/env/constants"
import PlanChangeNotification from "@modules/billing/components/PlanChangeNotification"
import { SelfHostedGitConnection } from "@modules/organization/Settings/components/SelfHostedGitConnection"
import { OrganizationNameSection } from "./OrganizationNameSection"
import { VCSHealthSection } from "./VCSHealthSection"
import { useOrganizationSettingsFieldsFragment } from "../fragments.generated"
import { Heading } from "gatsby-interface"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { OrganizationLimitsUsage } from "./OrganizationLimitsUsage"
import { PlanAddons } from "@modules/billing/components/PlanAddons"
import { PaymentMethod } from "@modules/billing/components/PaymentMethod"
import { PaymentHistory } from "@modules/billing/components/PaymentHistory"
import { useFlags } from "@modules/featureFlags"
import { useWorkspaceFeaturesUsage } from "@modules/organization/shared/hooks/useWorkspaceFeaturesUsage"
import * as qs from "query-string"

function OrganizationSettingsGeneralSection({
  organizationId,
  name,
  billing,
  status,
  permissions,
  sections,
  sectionName,
  location,
}) {
  const { flags } = useFlags()
  const query = qs.parse(location?.search)
  const {
    planUpdateNotificationVisible,
    hidePlanUpdateNotification,
    requestedPlanName,
    clearPlanChangeRequest,
  } = usePlanChangeState(organizationId)

  const currentPlanName = billing && billing.plan && billing.plan.name
  const planUpdateDone =
    requestedPlanName && requestedPlanName === currentPlanName
  const paymentIsDefined = Boolean(billing?.creditCard)

  const featuresUsage = useWorkspaceFeaturesUsage(organizationId)

  useEffect(() => {
    featuresUsage.refetch()
  }, [])

  useEffect(() => {
    if (planUpdateDone) {
      setTimeout(() => clearPlanChangeRequest(), 10000)
    }
  }, [planUpdateDone])

  return (
    <React.Fragment>
      <Heading as={`h2`} css={visuallyHiddenCss}>
        {sectionName}
      </Heading>
      {planUpdateNotificationVisible && (
        <PlanChangeNotification
          currentPlanName={currentPlanName}
          requestedPlanName={requestedPlanName}
          onDismiss={hidePlanUpdateNotification}
        />
      )}
      {sections.map(({ name: sectionName }) => {
        let cardElement
        switch (sectionName) {
          case OrgSettingsSection.Plan:
            cardElement = (
              <PlanInformation
                organizationId={organizationId}
                permissionToCreate={!!permissions.billing.create}
              />
            )
            break
          case OrgSettingsSection.Status:
            cardElement = (
              <OrganizationStatus
                organizationStatus={status}
                organizationName={name}
                organizationId={organizationId}
              />
            )
            break
          case OrgSettingsSection.Details:
            cardElement = (
              <OrganizationNameSection
                permissionToEdit={permissions.organization.edit}
                organizationId={organizationId}
                organizationName={name}
              />
            )
            break
          case OrgSettingsSection.Addons:
            cardElement = (
              <PlanAddons
                permissionToEdit={permissions.organization.edit}
                organizationId={organizationId}
                organizationName={name}
              />
            )
            break
          case OrgSettingsSection.Usage:
            cardElement = (
              <OrganizationLimitsUsage organizationId={organizationId} />
            )

            break
          case OrgSettingsSection.PaymentMethod:
            cardElement = paymentIsDefined ? (
              <OrganizationPermissions
                id={organizationId}
                resource="billing"
                action="read"
              >
                <PaymentMethod organizationId={organizationId} />
              </OrganizationPermissions>
            ) : null
            break
          case OrgSettingsSection.PaymentHistory:
            cardElement = paymentIsDefined ? (
              <OrganizationPermissions
                id={organizationId}
                resource="billing"
                action="read"
              >
                <PaymentHistory organizationId={organizationId} />
              </OrganizationPermissions>
            ) : null
            break
          case OrgSettingsSection.SelfHostedGit:
            cardElement = flags.onPremVcsConnection ? (
              <SelfHostedGitConnection
                organizationId={organizationId}
                popConnectionModal={!!query?.popConnectionModal}
                sourceType={query?.sourceType}
              />
            ) : null
            break

          case OrgSettingsSection.VCSHealth:
            cardElement = (
              <VCSHealthSection
                workspaceId={organizationId}
                isOwner={permissions.role === "OWNER"}
              />
            )
            break

          default:
            if (!isProduction) {
              console.error(`Unexpected section found: ${sectionName}`)
            }
            cardElement = null
        }

        return <React.Fragment key={sectionName}>{cardElement}</React.Fragment>
      })}
    </React.Fragment>
  )
}

function OrganizationSettings({ organizationId, sections, location }) {
  const { data } = useOrganizationSettingsFieldsFragment(organizationId)

  const { name, status, billing, permissions } = data || {}

  return sections.map(({ name: sectionName, active, subsections }) => {
    if (!active) {
      return null
    }
    let settingsSection = null
    switch (sectionName) {
      case OrgSettingsSection.General:
        settingsSection = (
          <OrganizationSettingsGeneralSection
            sectionName={sectionName}
            sections={subsections}
            organizationId={organizationId}
            name={name}
            billing={billing}
            status={status}
            permissions={permissions}
            location={location}
          />
        )
        break
      default:
        if (!isProduction) {
          console.error(`Unexpected section found: ${sectionName}`)
        }
        settingsSection = null
    }

    return <React.Fragment key={sectionName}>{settingsSection}</React.Fragment>
  })
}

OrganizationSettings.propTypes = {
  organizationId: PropTypes.string.isRequired,
}

export default OrganizationSettings
