import * as React from "react"
import { VisuallyHidden, ThemeCss, Theme } from "gatsby-interface"
import { Title, Eyebrow } from "./TitleAndEyebrow"
import { organizations as text } from "@modules/locales/default.js"
import { PlanTag } from "@modules/billing/shared/components/PlanTag"
import { TrialRemainingDays } from "@modules/billing/components/TrialRemainingDays"
import {
  OrganizationLimitsWidgets,
  LimitUsage,
} from "./OrganizationLimitsWidgets"

export type OrganizationHeaderProps = {
  organizationId: string
  organizationName?: string
  planName?: string
  activeTabLabel?: string
  trialDaysRemaining?: number | null
  limitsUsage?: LimitUsage[]
  isTrialing?: boolean
}

export function OrganizationHeader({
  organizationId,
  organizationName,
  planName,
  activeTabLabel,
  trialDaysRemaining,
  isTrialing,
}: OrganizationHeaderProps) {
  if (!organizationId || !organizationName) {
    return null
  }

  return (
    <div css={rootCss}>
      <div css={titleSectionCss}>
        <Eyebrow aria-hidden>{text.morphemes.organization}</Eyebrow>
        <div css={titleRowCss}>
          <Title css={titleCss}>
            <VisuallyHidden>{text.morphemes.organization}</VisuallyHidden>
            {organizationName}
            {activeTabLabel && (
              <VisuallyHidden> / {activeTabLabel}</VisuallyHidden>
            )}
          </Title>
          <PlanTag css={planTagCss} name={planName} />
        </div>
        {isTrialing && (
          <TrialRemainingDays days={trialDaysRemaining} css={trialInfoCss} />
        )}
      </div>
      <OrganizationLimitsWidgets
        organizationId={organizationId}
        css={limitsCss}
      />
    </div>
  )
}

/* styles */

const rootCss: ThemeCss = theme => ({
  display: `grid`,

  "& > div": {
    marginBottom: theme.space[7],
  },

  [theme.mediaQueries.desktop]: {
    display: `flex`,
  },
})

const titleSectionCss: ThemeCss = theme => [
  {
    marginRight: `auto`,
    marginBottom: theme.space[5],
  },
]

const titleRowCss: ThemeCss = theme => ({
  display: `flex`,
  flexWrap: `wrap`,
  paddingRight: theme.space[10],
})

const titleCss: ThemeCss = theme => ({
  marginRight: theme.space[4],
})

const planTagCss: ThemeCss = _theme => ({
  transform: `translateY(0.1em)`,
})

const trialInfoCss: ThemeCss = theme => ({
  marginTop: theme.space[3],
})

const limitsCss: ThemeCss = _theme => ({
  alignSelf: `flex-end`,
})
