import { ActivelyTrackedLimits } from "@modules/organization/shared/constants"
import { useFlags } from "@modules/featureFlags"
import { PlatformLimitSummaryName } from "@modules/graphql/types"
import { useWorkspaceDetailsShortcuts } from "@modules/organization/builds/hooks/useWorkspaceDetailsShortcuts"

export function useFilterOutNotTrackedPlatformLimit(organizationId: string) {
  const workspace = useWorkspaceDetailsShortcuts(organizationId)

  const { flags } = useFlags()

  const targetedLimits = workspace.isOnFreePlan
    ? ActivelyTrackedLimits.FREE
    : ActivelyTrackedLimits.DEFAULT

  const trackedLimits = [...targetedLimits, ...ActivelyTrackedLimits.FALL2022]

  const activeLimits = trackedLimits.filter(
    item => !ActivelyTrackedLimits.DEPRECATED.includes(item)
  )

  const shouldSeeNewLimit =
    workspace.isOnEnterprisePlan && flags.shouldSeePricingLimits
  const finalLimits = activeLimits.filter(item => {
    // show new limits to enterprise customers if flag is on
    if (shouldSeeNewLimit) {
      return true
    }

    // legacy and enterprise should not see new limits
    const shouldNotSeeLimit =
      (workspace.isOnLegacyPlan || workspace.isOnEnterprisePlan) &&
      [
        PlatformLimitSummaryName.MonthlyBuildMinutes,
        PlatformLimitSummaryName.TotalSiteRoutes,
      ].includes(item)
        ? false
        : true

    return shouldNotSeeLimit
  })

  return (name: string) => {
    return finalLimits.includes(name as PlatformLimitSummaryName)
  }
}
