import React from "react"
import { Router, Redirect } from "@gatsbyjs/reach-router"
import { PageWithTabsContentSection } from "@modules/ui/layouts/Containers"
import {
  SidebarColumn,
  Content,
  InnerLayout,
} from "@modules/ui/layouts/ColumnLayout"
import OrganizationSettings from "@modules/organization/Settings/components/OrganizationSettings"
import { useTracker } from "@modules/analytics"
import { useOrgSettingsNavigation } from "@modules/organization/Settings/components/OrganizationSettings.helpers"
import { getPathToOrgSettingsSection } from "@modules/organization/Settings/utils"
import { OrganizationPermissions } from "@modules/organization/permissions"
import { EmptyState, EmptyStatePrimaryAction } from "gatsby-interface"
import { getPathToOrgDetailsTab } from "@modules/organization/shared/utils"
import { orgDetails as orgDetailsText } from "@modules/locales/default.js"
import { OrgDetailsTab } from "../constants"

function OrganizationSettingsPaneInner({
  organizationId,
  sections,
  sidebarNavOptions,
  location,
}) {
  return (
    <PageWithTabsContentSection>
      <InnerLayout>
        <OrganizationPermissions
          id={organizationId}
          resource="organization"
          action="read"
          fallback={
            <div css={{ flexGrow: 1 }}>
              <EmptyState
                heading={orgDetailsText.headers.noAccessTitle}
                headingAs="h2"
                text={orgDetailsText.messages.noAccessDescription}
                primaryAction={
                  <EmptyStatePrimaryAction
                    to={getPathToOrgDetailsTab(
                      organizationId,
                      OrgDetailsTab.Sites
                    )}
                  >
                    {orgDetailsText.actions.backToWorkspace}
                  </EmptyStatePrimaryAction>
                }
                variant="BORDERED"
              />
            </div>
          }
        >
          <SidebarColumn options={sidebarNavOptions} />
          <Content>
            <OrganizationSettings
              organizationId={organizationId}
              sections={sections}
              location={location}
            />
          </Content>
        </OrganizationPermissions>
      </InnerLayout>
    </PageWithTabsContentSection>
  )
}

export default function OrganizationSettingsPane({
  organizationId,
  organizationDetails,
  location,
}) {
  const { trackPageViewed } = useTracker()

  React.useEffect(() => {
    trackPageViewed(`Organization Settings`)
  }, [])

  const { sections, sidebarNavOptions } = useOrgSettingsNavigation(
    organizationId,
    location,
    organizationDetails
  )

  return (
    <Router>
      {/*
        Index redirect from "base" path (/dashboard/organization/:organizationId/settings)
        to the first settings section
      */}
      <Redirect
        from="/"
        to={getPathToOrgSettingsSection(organizationId, sections[0].name)}
        noThrow
      />
      <OrganizationSettingsPaneInner
        path="/:section"
        organizationId={organizationId}
        sections={sections}
        sidebarNavOptions={sidebarNavOptions}
        location={location}
      />
    </Router>
  )
}
