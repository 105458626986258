import * as React from "react"
import {
  ThemeCss,
  Button,
  StyledSelect,
  Text,
  Modal,
  ModalCard,
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledModalActions,
  Notification,
  Link,
} from "gatsby-interface"
import { getPathToOrgDetailsTab } from "@modules/organization/shared/utils/index"
import { OrgDetailsTab } from "@modules/organization/details/constants"

import {
  ProrationAmount,
  AdditionalFeaturesInput,
  BillingInterval,
} from "@modules/graphql/types"
import { formatBandwidthAmount } from "./PlanAddons.utils"

export type ActionsProps = {
  onSave: () => void
  onCancel: () => void
  saveIsEnabled: boolean
  prorationResult?: ProrationAmount | null
  isChecking: boolean
  isSaving: boolean
  membersDowngradeIsBlocked?: boolean
  organizationId: string
}

export function Actions({
  onSave,
  onCancel,
  saveIsEnabled = false,
  prorationResult,
  isChecking,
  isSaving,
  membersDowngradeIsBlocked,
  organizationId,
}: ActionsProps) {
  return (
    <React.Fragment>
      {membersDowngradeIsBlocked && (
        <Notification
          tone="WARNING"
          variant="SECONDARY"
          showDismissButton={false}
          content={
            <React.Fragment>
              Your number of member add-ons can’t add up to less than your
              current member count. &nbsp;
              <Link
                to={getPathToOrgDetailsTab(
                  organizationId,
                  OrgDetailsTab.Members
                )}
                variant="SIMPLE"
              >
                View members
              </Link>
            </React.Fragment>
          }
          css={notificationCss}
        />
      )}
      <div css={actionsCss}>
        <Button onClick={onCancel} variant="SECONDARY">
          Cancel
        </Button>
        {isChecking && (
          <Text size="S" noMarginBottom={true} css={checkingMsgCss}>
            Checking...
          </Text>
        )}

        {prorationResult && (
          <Text size="S" noMarginBottom={true} css={checkingMsgCss}>
            You will be{" "}
            <b>
              billed a pro-rated amount of {prorationResult.formattedAmount}{" "}
              immediately
            </b>
            . <br />
            On your <b>next billing cycle</b>, your{" "}
            <b>
              subscription amount including add-ons will be{" "}
              {prorationResult?.formattedNextPeriodAmount}
            </b>
            .
          </Text>
        )}
        <Button
          onClick={onSave}
          disabled={!saveIsEnabled}
          loading={isSaving}
          loadingLabel="Saving"
          css={saveBtnCss}
        >
          Save add-ons
        </Button>
      </div>
    </React.Fragment>
  )
}

/* styles */

const actionsCss: ThemeCss = theme => ({
  marginTop: theme.space[8],
  display: `flex`,
  justifyContent: `space-between`,
  alignItems: `flex-start`,
  flexWrap: `wrap`,

  [theme.mediaQueries.desktop]: {
    flexWrap: `nowrap`,
  },
})

const saveBtnCss: ThemeCss = theme => ({
  flexShrink: 0,
})

const checkingMsgCss: ThemeCss = theme => ({
  alignSelf: `center`,
  marginTop: theme.space[5],
  textAlign: `right`,
  order: 1,
  width: `100%`,

  svg: {
    verticalAlign: `middle`,
    color: theme.colors.orange[80],
    transform: `translateY(-5%)`,
  },

  [theme.mediaQueries.desktop]: {
    width: `auto`,
    order: 0,
    marginTop: 0,
    paddingLeft: theme.space[8],
    marginLeft: `auto`,
    marginRight: theme.space[6],
  },
})

const notificationCss: ThemeCss = theme => ({
  marginTop: theme.space[6],
  marginBottom: `-${theme.space[4]}`,
})

/* ****************** next component ****************** */

export function Header() {
  return (
    <div css={theme => [rowCss(theme), headerRowCss(theme)]}>
      <span>Item</span>
      <span>Add-on limit</span>
      <span>Unit price</span>
      <span>Amount</span>
    </div>
  )
}

/* styles */

const headerRowCss: ThemeCss = theme => ({
  display: `none`,

  [theme.mediaQueries.desktop]: {
    display: `grid`,
    textTransform: `uppercase`,
    color: theme.colors.grey[50],
    fontSize: theme.fontSizes[0],
    letterSpacing: theme.letterSpacings.tracked,
  },
})

/* ****************** next component ****************** */

export type Addon = {
  name: `users` | `bandwidth` | `concurrency`
  label: string
  limit?: number | null
  limitAdjusted?: number | null
  customAdjustedLimit?: string
  unitPrice: string
  interval?: BillingInterval | null
  quantity?: number | null
  customQuantity?: string
  htmlInfo?: React.ReactNode
  initialQuantity: React.MutableRefObject<number | null | undefined>
  onChange: (val: number) => void
  options?: { label: string; value: string }[]
  customizedLimit?: string
}

const IntervalLabel: Record<BillingInterval, string> = {
  [BillingInterval.Annual]: `year`,
  [BillingInterval.Monthly]: `month`,
}

export type AddonsProps = {
  data: Addon[]
  isInEditMode: boolean
}

export function Addons({ data, isInEditMode }: AddonsProps) {
  return (
    <React.Fragment>
      {data.map(
        ({
          label,
          limitAdjusted = 0,
          customAdjustedLimit,
          interval,
          unitPrice,
          quantity = 0,
          customQuantity,
          onChange,
          options,
        }) => {
          const handleChange: React.ChangeEventHandler<
            HTMLSelectElement
          > = e => {
            onChange(+e.target.value)
          }

          return (
            <div css={theme => [rowCss(theme), itemRowCss(theme)]} key={label}>
              <span>{label}</span>
              <span>
                <i>Limit: </i>
                {customAdjustedLimit
                  ? customAdjustedLimit
                  : `${limitAdjusted} additional`}
              </span>
              <span>
                <i>Unit price: </i>
                {unitPrice} / {interval && IntervalLabel[interval]}
              </span>
              {isInEditMode && options ? (
                <StyledSelect
                  onChange={handleChange}
                  css={theme => [
                    selectCss(theme),
                    !isInEditMode && disabledCss(theme),
                  ]}
                  value={quantity as number}
                  options={options}
                  disabled={!isInEditMode}
                />
              ) : (
                <span>
                  <i>Amount: </i> {customQuantity ? customQuantity : quantity}
                </span>
              )}
            </div>
          )
        }
      )}
    </React.Fragment>
  )
}

/* styles */

const rowCss: ThemeCss = theme => ({
  display: `grid`,
  columnGap: theme.space[5],
  alignItems: `center`,
  gridTemplateColumns: `1fr`,

  "& > span:nth-last-child": {
    textAlign: `right`,
  },

  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: `1fr 1.5fr .85fr 8rem`,
  },
})

const itemRowCss: ThemeCss = theme => ({
  borderTop: `1px solid ${theme.colors.standardLine}`,
  marginTop: theme.space[3],
  paddingTop: theme.space[3],
  color: theme.colors.grey[80],
  fontSize: theme.fontSizes[1],
  minHeight: `2rem`,

  i: {
    color: theme.colors.grey[50],
    fontStyle: `normal`,
  },

  "& > span:first-child": {
    fontWeight: theme.fontWeights.bold,
    marginBottom: theme.space[3],
  },

  select: {
    marginTop: theme.space[3],
    marginBottom: theme.space[3],
  },

  [theme.mediaQueries.desktop]: {
    "& > span:first-child": {
      fontWeight: theme.fontWeights.body,
      marginBottom: 0,
    },

    select: {
      marginTop: 0,
      marginBottom: 0,
    },

    i: {
      display: `none`,
    },
  },
})

const selectCss: ThemeCss = theme => ({
  width: `100%`,
  fontSize: theme.fontSizes[1],
  height: `2.3rem`,
})

const disabledCss: ThemeCss = theme => ({
  border: 0,
  textAlign: `right`,
  background: `none`,
  padding: 0,

  "&:disabled": {
    background: `none`,
    color: theme.colors.grey[80],
    opacity: 1,
  },
})

/* ****************** next component ****************** */

export type ConfirmationProps = {
  isOpen: boolean
  onDismiss: () => void
  onConfirm: () => void
  getFeatures: () => AdditionalFeaturesInput[]
  proration?: ProrationAmount | null
}

export function Confirmation({
  isOpen,
  onDismiss,
  onConfirm,
  getFeatures,
  proration,
}: ConfirmationProps) {
  const BANDWIDTH_INCREMENT_MULTIPLIER = 100
  const [featuresStr, setFeaturesStr] = React.useState(``)

  const handleApply = () => {
    onDismiss()
    onConfirm()
  }

  React.useEffect(() => {
    if (isOpen) {
      const features = getFeatures()

      if (features.length > 0) {
        const str = features.reduce(
          (
            acc: string[],
            cur: AdditionalFeaturesInput,
            idx: number,
            arr: AdditionalFeaturesInput[]
          ) => {
            if (typeof cur.quantity !== `number`) {
              return acc
            }

            let part: string

            if (cur.name === `bandwidth`) {
              part = `${formatBandwidthAmount(
                cur.quantity * BANDWIDTH_INCREMENT_MULTIPLIER
              )} bandwidth`
            } else {
              part = `${cur.quantity} ${cur.name}`
            }

            if (cur.name === `users`) {
              part = part.replace(
                `users`,
                cur.quantity === 1 ? `member` : `members`
              )
            }

            if (idx === arr.length - 1 && arr.length > 1) {
              part = ` and ${part}`
            } else if (idx > 0) {
              part = `, ${part}`
            }

            acc.push(part)

            return acc
          },
          []
        )

        setFeaturesStr(str.join(``))
      }
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      aria-label="Confirm add-ons apply"
    >
      <ModalCard>
        <StyledModal variant="ACTION">
          <StyledModalHeader onCloseButtonClick={onDismiss}>
            Confirm adding add-on to your subscription.
          </StyledModalHeader>
          <StyledModalBody>
            <div css={confimationMsgCss}>
              <Text>
                You will be{" "}
                <b>
                  billed a pro-rated amount of{" "}
                  <strong>{proration?.formattedAmount}</strong> for{" "}
                  {featuresStr} immediately
                </b>
                .
              </Text>
              <Text>
                On your next billing cycle, your subscription amount including
                add-ons will be{" "}
                <strong>{proration?.formattedNextPeriodAmount}</strong>.
              </Text>
              <Text>
                <strong>Are you sure you want to purchase this add-on?</strong>
              </Text>
            </div>
            <StyledModalActions>
              <Button
                variant="SECONDARY"
                tone="NEUTRAL"
                type="button"
                onClick={onDismiss}
              >
                Cancel
              </Button>
              <Button variant="PRIMARY" type="submit" onClick={handleApply}>
                Yes, apply
              </Button>
            </StyledModalActions>
          </StyledModalBody>
        </StyledModal>
      </ModalCard>
    </Modal>
  )
}

/* styles */

const confimationMsgCss: ThemeCss = theme => ({
  p: {
    marginBottom: theme.space[4],
  },

  strong: {
    color: theme.colors.purple[60],
  },
})

/* ****************** next component ****************** */

export type PreviewProps = {
  data: Addon[]
}

export function Preview({ data }: PreviewProps) {
  const addons = data.filter(item => item.htmlInfo) || []
  const addonsActive = addons.length > 0

  return (
    <React.Fragment>
      <Text size="S" noMarginBottom={true}>
        {addonsActive
          ? `Amounts are in addition to the Plan limits above.`
          : `There is no active add-on.`}
      </Text>

      {addonsActive && (
        <div css={previewCss}>
          {addons.map(({ htmlInfo, label }) => (
            <Text size="S" noMarginBottom={true} key={label}>
              {htmlInfo}
            </Text>
          ))}
        </div>
      )}
    </React.Fragment>
  )
}

/* styles */

const previewCss: ThemeCss = theme => ({
  borderTop: `1px solid ${theme.colors.standardLine}`,
  marginTop: theme.space[6],
  paddingTop: theme.space[3],

  "& > p": {
    marginTop: theme.space[4],
  },
})
