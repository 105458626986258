import * as React from "react"
import { ThemeCss, Link, EmptyState } from "gatsby-interface"
import { orgMembers as orgMembersText } from "@modules/locales/default.js"
import { MdHelpOutline } from "react-icons/md"

export type MembersEmptyProps = {
  organizationId: string
  isFreePlan?: boolean
}

export function MembersEmpty({
  organizationId,
  isFreePlan,
}: MembersEmptyProps) {
  let heading = orgMembersText.headers.noViewPermissions
  let message = orgMembersText.messages.noViewPermissionsDescription
  let linkUrl = `${window.location.origin}/docs/managing-contributors-and-permissioning/`
  let linkText = orgMembersText.labels.docsLink

  if (isFreePlan) {
    heading = orgMembersText.headers.noPlan
    message = orgMembersText.messages.noPlan
    linkUrl = `${window.location.origin}/dashboard/pricing/${organizationId}`
    linkText = orgMembersText.labels.noPlan
  }

  return (
    <EmptyState
      variant="BORDERED"
      heading={heading}
      headingAs="h3"
      text={message}
      secondaryAction={
        <Link css={linkCss} target="_blank" href={linkUrl}>
          <MdHelpOutline css={iconCss} />
          {linkText}
        </Link>
      }
    />
  )
}

/* styles */

const linkCss: ThemeCss = _theme => ({
  display: `inline`,
})

const iconCss: ThemeCss = theme => ({
  verticalAlign: `middle`,
  marginRight: theme.space[2],
})
