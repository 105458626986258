import * as React from "react"
import { Organization, SubscriptionStatus } from "@modules/graphql/types"
import { isBefore, subMonths, format } from "date-fns"
import {
  Modal,
  ModalCard,
  Heading,
  Text,
  Button,
  ThemeCss,
  ProfessionalPlanIcon,
} from "gatsby-interface"
import { FormattedMessage, commonMessageTags } from "@modules/locales"
import { MdArrowForward } from "react-icons/md"
import { navigate } from "gatsby"
import {
  getPathToUpgradeToProfessional,
  getPathToOrgSettings,
} from "@modules/organization/shared/utils"
import { LocalStorageItems } from "@modules/localStorage/constants"
import { planInformation as text } from "@modules/locales/default.js"

type PlanTrialEndedPromptProps = {
  organization?: Omit<Organization, "createdAt" | "updatedAt" | "rteSummary">
}

export function PlanTrialEndedPrompt({
  organization,
}: PlanTrialEndedPromptProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    if (!organization) {
      return
    }

    const isFree = organization.billing?.status === SubscriptionStatus.Free

    if (isFree) {
      const now = new Date()

      const trialEnd = organization.billing?.trialEnd
      const trialEnded = trialEnd && isBefore(new Date(trialEnd), now)
      const trialEndedMonthAgo =
        trialEnd && isBefore(new Date(trialEnd), subMonths(now, 1))

      const alreadyDecided = Boolean(readNote(organization.id))
      const isNotEligibleToPrompt = trialEndedMonthAgo || alreadyDecided

      // does nothing if trial ended more than a month ago
      // or the user already reacted to the prompt before
      if (isNotEligibleToPrompt) {
        return
      }

      // opens the modal if the workspace was on a trial period
      // does not relates to workspaces created as Free from the beginning
      if (trialEnded) {
        setIsOpen(true)
      }
    }
  }, [organization])

  const handleUpgrade = () => {
    writeNote(organization?.id)
    setIsOpen(false)
    navigate(getPathToUpgradeToProfessional(organization?.id))
  }

  const handleDismiss = () => {
    writeNote(organization?.id)
    setIsOpen(false)
    navigate(getPathToOrgSettings(organization?.id))
  }

  return (
    <Modal
      isOpen={isOpen}
      data-gtm="Plan Trial has ended - modal"
      aria-label="Plan Trial has ended"
    >
      <ModalCard css={cardCss}>
        <ProfessionalPlanIcon css={iconCss} />
        <Heading as="h3">{text.headers.yourTrialHasEnded}</Heading>
        <Text>
          <FormattedMessage<"plan", "strong">
            message={text.messages.upgradeNowToKeepEnjoying}
            values={{
              plan: `Professional`,
            }}
            tags={commonMessageTags}
          />
        </Text>
        <Button
          size="XL"
          rightIcon={<MdArrowForward />}
          onClick={handleUpgrade}
        >
          {text.labels.continueToPayment}
        </Button>
        <Button variant="GHOST" onClick={handleDismiss}>
          {text.labels.noThanksContinueFree}
        </Button>
      </ModalCard>
    </Modal>
  )
}

/* utils */

function writeNote(orgId: string) {
  window.localStorage.setItem(
    `${LocalStorageItems.GatsbyTrialPromptInteraction}:${orgId}`,
    format(new Date(), `yyyy-MM-dd`)
  )
}

function readNote(orgId: string) {
  return window.localStorage.getItem(
    `${LocalStorageItems.GatsbyTrialPromptInteraction}:${orgId}`
  )
}

/* styles */

const cardCss: ThemeCss = theme => ({
  display: `grid`,
  justifyItems: `center`,
  padding: `${theme.space[8]} ${theme.space[5]}`,
  gap: theme.space[8],

  svg: {
    marginBottom: `-${theme.space[5]}`,
  },

  h3: {
    fontSize: theme.fontSizes[5],
    fontWeight: theme.fontWeights.semiBold,
    textAlign: `center`,
  },

  p: {
    textAlign: `center`,
    margin: 0,
  },

  [theme.mediaQueries.desktop]: {
    padding: theme.space[10],
  },
})

const iconCss: ThemeCss = theme => ({
  width: theme.space[9],
  height: theme.space[9],
})
