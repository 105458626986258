import { useOrganizationPlanFeaturesQuery } from "@modules/organization/queries.generated"

export function useOrganizationPlanFeatures(orgId: string) {
  const { data, loading, error } = useOrganizationPlanFeaturesQuery({
    variables: { id: orgId },
    fetchPolicy: "cache-only",
  })

  useOrganizationPlanFeaturesQuery({
    variables: { id: orgId },
    fetchPolicy: "network-only",
    skip: Boolean(data),
  })

  const result = {
    users: {
      max: data?.organizationDetails?.billing?.plan?.baseFeatures?.users?.max,
      quantity:
        data?.organizationDetails?.billing?.plan?.totalFeatures?.users
          ?.quantity,
    },
    sites: {
      quantity: data?.organizationDetails?.billing?.plan?.baseFeatures?.sites,
    },
    domains: {
      quantity: data?.organizationDetails?.billing?.plan?.baseFeatures?.domains,
    },
    integrations: {
      quantity:
        data?.organizationDetails?.billing?.plan?.baseFeatures
          ?.thirdPartyHostingIntegrations,
    },
  }

  return [result, { loading, error }] as const
}
