import * as React from "react"
import { Router, Redirect } from "@gatsbyjs/reach-router"
import useSetFSRole from "@modules/fullStory/hooks/useSetFSRole"
import { getPathToOrgDetailsTab } from "@modules/organization/shared/utils"
import OrganizationDetailsTabsNav from "@modules/organization/details/components/OrganizationDetailsTabsNav"
import {
  OrgDetailsTab,
  ORG_DETAILS_DEFAULT_TAB,
} from "@modules/organization/details/constants"
import SingleOrganizationSitesList from "@modules/organization/list/components/SingleOrganizationSitesList"
import OrganizationMembersPane from "@modules/organization/details/components/OrganizationMembersPane"
import OrganizationSettingsPane from "@modules/organization/details/components/OrganizationSettingsPane"
import {
  Organization,
  SourceControlRepository,
  Site,
} from "@modules/graphql/types"

const paneComponentsByTab = {
  [OrgDetailsTab.Sites]: SingleOrganizationSitesList,
  [OrgDetailsTab.Members]: OrganizationMembersPane,
  [OrgDetailsTab.Settings]: OrganizationSettingsPane,
}

type OrganizationRepository = Pick<
  SourceControlRepository,
  "id" | "name" | "nameWithOwner" | "url" | "provider"
> & {
  sites?: Pick<
    Site,
    | "id"
    | "name"
    | "publicName"
    | "branch"
    | "updatedAt"
    | "previewUrl"
    | "previewStatus"
    | "previewProtection"
    | "repositoryId"
    | "buildsEnabled"
    | "incrementalPreviewsEnabled"
    | "dataBuildsEnabled"
    | "previewBuildsEnabled"
    | "parentOrganizationId"
  >[]
}

type OrganizationDetailsTabsContainerProps = {
  organizationId: string
  organizationDetails: Omit<
    Organization,
    "createdAt" | "updatedAt" | "rteSummary"
  >
  organizationRepositories: OrganizationRepository[]
  tabs: { tab: string; label: string; hasSections: boolean }[]
  activeTab?: string
  loadMore: () => void
  loadingMore: boolean
  searchText: string
  debouncedSearchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  numberOfSites?: number | null
}

export default function OrganizationDetailsTabsContainer({
  organizationId,
  organizationDetails,
  organizationRepositories,
  tabs,
  activeTab,
  loadMore,
  loadingMore,
  searchText,
  debouncedSearchText,
  setSearchText,
  numberOfSites,
}: OrganizationDetailsTabsContainerProps) {
  const permissions = organizationDetails && organizationDetails.permissions
  const role = permissions && permissions.role
  // Record user role for site in fullstory
  useSetFSRole(role)

  return (
    <React.Fragment>
      <OrganizationDetailsTabsNav
        tabs={tabs}
        activeTab={activeTab}
        organizationId={organizationId}
        numberOfSites={numberOfSites}
      />
      <Router>
        {tabs.map(({ tab, hasSections }) => {
          const Pane = paneComponentsByTab[tab]
          return (
            <Pane
              key={tab}
              // If there are sections for a tab, delegate their routes to the pane component
              // @ts-ignore
              path={hasSections ? `${tab}/*` : tab}
              organizationId={organizationId}
              organizationDetails={organizationDetails}
              organizationRepositories={organizationRepositories}
              loadMore={loadMore}
              loadingMore={loadingMore}
              searchText={searchText}
              debouncedSearchText={debouncedSearchText}
              setSearchText={setSearchText}
              numberOfSites={numberOfSites}
            />
          )
        })}
        {/*
          Index redirect from "base" path (/dashboard/organization/:organizationId)
          to the ORG_DETAILS_DEFAULT_TAB tab
        */}
        <Redirect
          from={`/`}
          to={getPathToOrgDetailsTab(organizationId, ORG_DETAILS_DEFAULT_TAB)}
          noThrow
        />
      </Router>
    </React.Fragment>
  )
}
