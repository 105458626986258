import React, { useState } from "react"
import PropTypes from "prop-types"
import { MdLink } from "react-icons/md"
import { Button, Text } from "gatsby-interface"
import copyToClipboard from "@modules/toolkit/helpers/copyToClipboard"

const copyLinkCss = theme => ({
  fontSize: theme.fontSizes[1],
  fontFamily: theme.fonts.body,
  color: theme.colors.grey[50],

  transition: "unset",
  svg: {
    marginLeft: theme.space[2],
  },
  ":hover": {
    background: `none`,
    color: theme.colors.grey[50],
  },
  ":active": {
    background: `none`,
    color: theme.colors.purple[50],
  },
})

const textCss = theme => ({
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[50],
  margin: "unset",
  padding: `${theme.space[3]} 0`,
  border: "1px solid transparent", // avoid jumping
  marginRight: theme.space[5],
})

function CopyLink({
  onClick,
  content,
  label = "Share",
  successLabel = "Link copied to clipboard!",
  ...props
}) {
  const [copied, setCopied] = useState(false)

  return copied ? (
    <Text css={textCss}>{successLabel}</Text>
  ) : (
    <Button
      variant="GHOST"
      css={copyLinkCss}
      onClick={async ev => {
        if (onClick) {
          onClick(ev)
        }
        await copyToClipboard(content)

        setCopied(true)

        setTimeout(() => {
          setCopied(false)
        }, props.delay)
      }}
      rightIcon={<MdLink />}
    >
      {label}
    </Button>
  )
}

CopyLink.defaultProps = {
  delay: 5000,
}

CopyLink.propTypes = {
  content: PropTypes.string.isRequired,
  delay: PropTypes.number,
  onClick: PropTypes.func,
}

export default CopyLink
