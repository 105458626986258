import * as React from "react"
import Loading from "@modules/ui/components/Loading"
import { Waypoint } from "react-waypoint"
import { Spacer } from "gatsby-interface"

export type MembersPaginationProps = {
  loadMore: () => void
  loadingMore: boolean
}

export function MembersPagination({
  loadingMore,
  loadMore,
}: MembersPaginationProps) {
  return (
    <React.Fragment>
      {loadingMore && (
        <div css={{ textAlign: "center" }}>
          <Spacer size={3} />
          <Loading variant="baby" message={`Loading members...`} />
        </div>
      )}

      {!loadingMore && <Waypoint onEnter={loadMore} />}
    </React.Fragment>
  )
}
