import * as React from "react"
import { CloudResource } from "@modules/graphql/types"
import { Text, Button, ThemeCss, SelectFieldBlock } from "gatsby-interface"
import { navigate } from "@gatsbyjs/reach-router"

export type DetailedAccessInfoInfo = {
  organizationId: string
  sites?: CloudResource[]
}

export function DetailedAccessInfo({
  sites,
  organizationId,
}: DetailedAccessInfoInfo) {
  if (!sites?.length) {
    return null
  }

  const siteOptions = sites.map(item => ({
    label: `${item.publicName}`,
    value: `${item.resourceId}`,
  }))

  const options = [
    {
      label: `Select site`,
      value: ``,
    },
    ...siteOptions,
  ]

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value) {
      navigate(`/dashboard/${organizationId}/sites/${e.currentTarget.value}`)
    }
  }

  return (
    <SelectFieldBlock
      id="installationField"
      name="installationField"
      label={
        <span>
          with access to <b>{siteOptions.length}</b> site
          {siteOptions.length ? `s` : ``}:
        </span>
      }
      options={options}
      onChange={handleOnChange}
      layout="horizontal"
      css={selectCss}
    />
  )
}

export type SimplifiedAccessInfoProps = {
  loading: boolean
  onCheck: () => void
}

export function SimplifiedAccessInfo({
  loading = false,
  onCheck,
}: SimplifiedAccessInfoProps) {
  return (
    <Text as="span" size="S" css={textCss}>
      with limited access to sites.
      <Button size="S" variant="GHOST" onClick={onCheck} loading={loading}>
        Check details
      </Button>
    </Text>
  )
}

/* styles */

const textCss: ThemeCss = theme => ({
  color: theme.colors.grey[50],
})

const selectCss: ThemeCss = theme => ({
  display: `flex`,

  label: {
    width: `auto`,
    alignSelf: `center`,
    color: theme.colors.grey[50],
    flexShrink: 0,
    marginBottom: 0,
    padding: 0,
    paddingRight: theme.space[3],
    paddingLeft: 0,
    minWidth: `auto`,
  },
  select: {
    fontSize: theme.fontSizes[0],
    maxWidth: `10rem`,
  },
})
