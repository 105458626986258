import * as React from "react"
import {
  Contributor as ContributorType,
  CloudResourceType,
  CloudRole,
  ContributorRole,
} from "@modules/graphql/types"
import {
  Card,
  Avatar,
  ThemeCss,
  Heading,
  Text,
  Badge,
  BadgeProps,
  Spacer,
} from "gatsby-interface"
import { InviteeActions } from "./InviteeActions"
import { MemberActionsDropdown } from "./MemberActionsDropdown"
import { SimplifiedAccessInfo, DetailedAccessInfo } from "./Member.parts"
import { useWorkspaceMemberResourcesQuery } from "@modules/organization/contributors/queries.generated"

export type MemberProps = {
  member: ContributorType
  organizationId: string
  hasPermissionToRead: boolean
  hasPermissionToDelete: boolean
  hasPermissionToInvite: boolean
  recalculateUsage?: () => void
}

export function Member({
  member,
  organizationId,
  hasPermissionToRead,
  hasPermissionToDelete,
  hasPermissionToInvite,
  recalculateUsage,
}: MemberProps) {
  const [isDetailedAccessVisible, setIsDetailedAccessVisible] =
    React.useState(false)

  const { data, loading } = useWorkspaceMemberResourcesQuery({
    variables: {
      workspaceId: organizationId,
      memberId: member.id,
    },
    skip: !isDetailedAccessVisible,
  })

  const sites = data?.workspaceMemberResources?.filter(
    item => item.resourceType === CloudResourceType.Site
  )

  const isInvitee = member.contributorRole === ContributorRole.Invited

  const isEntitledToManage =
    hasPermissionToDelete &&
    [
      ContributorRole.Editor,
      ContributorRole.Viewer,
      ContributorRole.Admin,
    ].includes(member?.contributorRole)

  const hasLimitedAccess = member?.resources?.some(
    item =>
      item.resourceType === CloudResourceType.Organization &&
      item.resourceId === organizationId &&
      [CloudRole.LimitedContributor, CloudRole.LimitedReader].includes(
        item.role
      )
  )

  const handleCheck = () => {
    setIsDetailedAccessVisible(true)
  }

  const roleBadgeVariants: Record<ContributorRole, BadgeProps> = {
    [ContributorRole.Owner]: {
      variant: `PILL`,
    },
    [ContributorRole.Admin]: {
      variant: `STATUS`,
    },
    [ContributorRole.Editor]: {
      variant: `STATUS`,
      tone: `SUCCESS`,
    },
    [ContributorRole.Viewer]: {
      variant: `STATUS`,
      tone: `NEUTRAL`,
    },
    [ContributorRole.Invited]: {},
    [ContributorRole.Member]: {},
  }

  const badgeProps =
    roleBadgeVariants[member.contributorRole] ||
    roleBadgeVariants[ContributorRole.Viewer]

  return (
    <Card css={cardCss}>
      <Avatar
        css={avatarCss}
        src={member.avatarUrl ?? ``}
        label={
          member.avatarUrl ? `${member.name} avatar` : `Default user avatar`
        }
        size={`L`}
      />

      <div css={contentCss}>
        <div css={personCss}>
          <Heading fontVariant="UI" css={headingCss}>
            <span css={nameCss}>
              {member.contributorRole === ContributorRole.Invited
                ? `Pending Invite`
                : member.name}{" "}
            </span>
          </Heading>

          <Text size="S" noMarginBottom={true}>
            {member.email}
          </Text>
        </div>

        {!isInvitee && hasPermissionToRead && (
          <React.Fragment>
            <div css={separatorCss} />

            <div css={accessCss}>
              <Badge size="M" {...badgeProps}>
                {member.contributorRole}
              </Badge>{" "}
              <Spacer size={3} />
              {hasLimitedAccess && (
                <>
                  {loading && (
                    <Text as="span" size="S" css={loadingCss}>
                      Loading...
                    </Text>
                  )}

                  {isDetailedAccessVisible ? (
                    <DetailedAccessInfo
                      sites={sites}
                      organizationId={organizationId}
                    />
                  ) : (
                    <SimplifiedAccessInfo
                      loading={loading}
                      onCheck={handleCheck}
                    />
                  )}
                </>
              )}
            </div>
          </React.Fragment>
        )}
      </div>

      <div css={actionsCss}>
        {isInvitee && hasPermissionToInvite && (
          <InviteeActions
            member={member}
            resourceId={organizationId}
            organizationId={organizationId}
            recalculateUsage={recalculateUsage}
          />
        )}

        {isEntitledToManage && (
          <MemberActionsDropdown
            member={member}
            resourceId={organizationId}
            recalculateUsage={recalculateUsage}
          />
        )}
      </div>
    </Card>
  )
}

/* styles */

const cardCss: ThemeCss = theme => ({
  padding: theme.space[7],
  display: `grid`,
  gridTemplateColumns: `auto 1fr`,

  [theme.mediaQueries.tablet]: {
    gridTemplateColumns: `auto 1fr auto`,
  },
})

const avatarCss: ThemeCss = theme => ({
  display: `block`,
  marginRight: theme.space[5],
})

const contentCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[4],
  alignItems: `center`,
  gridColumn: ` 1 / 3`,
  marginTop: theme.space[5],

  [theme.mediaQueries.tablet]: {
    gridColumn: `2 / 3`,
    gridTemplateColumns: `auto auto 1fr`,
    marginTop: 0,
  },
})

const personCss: ThemeCss = _theme => ({
  alignSelf: `start`,
})

const accessCss: ThemeCss = theme => ({
  display: `flex`,
  flexWrap: `wrap`,
  alignItems: `center`,
  gap: theme.space[2],
})

const actionsCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  gridColumn: ` 2 / 3`,
  gridRow: `1 / 2`,
  justifyContent: `flex-end`,

  [theme.mediaQueries.tablet]: {
    gridColumn: `3 / 4`,
  },
})

const separatorCss: ThemeCss = theme => ({
  display: `none`,

  [theme.mediaQueries.desktop]: {
    display: `block`,
    width: `1px`,
    backgroundColor: theme.colors.grey[30],
    height: `100%`,
    marginLeft: theme.space[3],
    marginRight: theme.space[3],
  },
})

const headingCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[2],
  whiteSpace: `nowrap`,
  display: `flex`,
  alignItems: `baseline`,
  minHeight: theme.space[7],
})

const nameCss: ThemeCss = theme => ({
  display: `inline-block`,
  marginRight: theme.space[5],
})

const loadingCss: ThemeCss = theme => ({
  color: theme.colors.grey[50],
})
