import * as React from "react"

export function useDisabledDataBuildsForSiteNotifications(siteId: string) {
  const isBrowser = typeof window !== `undefined`

  const disabledDataBuildsForSiteNotificationKey = `disabledDataBuildsForSite:${siteId}`

  const disabledDataBuildsForSiteVisibleInitValue =
    isBrowser &&
    window.sessionStorage.getItem(disabledDataBuildsForSiteNotificationKey)
      ? false
      : true

  const [
    disabledDataBuildsForSiteVisible,
    setDisabledDataBuildsForSiteVisible,
  ] = React.useState(disabledDataBuildsForSiteVisibleInitValue)

  function hideDisabledDataBuildsForSiteNotification() {
    setDisabledDataBuildsForSiteVisible(false)
    window.sessionStorage.setItem(disabledDataBuildsForSiteNotificationKey, `1`)
  }

  return {
    disabledDataBuildsForSiteVisible,
    hideDisabledDataBuildsForSiteNotification,
  }
}
