import * as React from "react"
import { ThemeCss, ProgressBar, Theme } from "gatsby-interface"
import { getProgressColor } from "./OrganizationLimitsUsage.utils"

export type Metric = {
  name?: string
  label?: string
  limit?: string | null
  usage?: string | null
  percentage?: number
  isRenewable: boolean
  isRecalculating: boolean
}

export type MetricsProps = {
  data: Metric[]
}

export function Metrics({ data }: MetricsProps) {
  if (data?.length && data.length < 1) {
    return null
  }

  return (
    <ul css={metricsCss}>
      {data.map(
        ({ label, isRenewable, usage, limit, percentage, isRecalculating }) => {
          if (!label) {
            return null
          }

          return (
            <li key={label}>
              <span>
                {label} {isRenewable ? `*` : ``}
              </span>
              <span css={theme => valueCss(theme, isRecalculating)}>
                {isRecalculating ? `Updating ...` : `${usage} / ${limit}`}
              </span>
              <div css={theme => graphCss(theme, isRecalculating)}>
                <ProgressBar
                  value={isRecalculating ? 0 : percentage || 0}
                  max={100}
                  aria-describedby={label}
                  height={8}
                  getProgressColor={getProgressColor}
                />
              </div>
            </li>
          )
        }
      )}
    </ul>
  )
}

/* styles */

const metricsCss: ThemeCss = theme => ({
  listStyle: `none`,
  margin: 0,
  columnGap: theme.space[5],
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[80],

  "& > li": {
    lineHeight: 1,
    display: `grid`,
    gridTemplateColumns: `1fr`,
    alignItems: `center`,
    borderTop: `1px solid ${theme.colors.standardLine}`,

    margin: 0,
    rowGap: theme.space[3],
    padding: `${theme.space[4]} 0`,

    [theme.mediaQueries.desktop]: {
      padding: `${theme.space[4]}`,
      gridTemplateColumns: `1fr 1fr 2fr`,
    },
  },

  "& > li:last-of-type": {
    borderBottom: `1px solid ${theme.colors.standardLine}`,
  },

  "& > li > span:first-of-type:first-letter": {
    textTransform: `uppercase`,
  },
})

const graphCss = (theme: Theme, isRenewable = false) => [
  {
    maxWidth: `10rem`,
    margin: `${theme.space[1]} 0 ${theme.space[3]}`,

    [theme.mediaQueries.desktop]: {
      margin: 0,
    },
  },
  isRenewable && {
    "& > div": {
      border: `1px solid ${theme.colors.grey[30]}`,
      background: `none`,
    },
  },
]

const valueCss = (theme: Theme, isRenewable = false) => [
  isRenewable && {
    color: theme.colors.grey[50],
    fontStyle: `italic`,
  },
]
