import * as merge from "deepmerge"
import { SourceControlRepository } from "@modules/graphql/types"

export function mergeOrgDetails(
  /* eslint-disable @typescript-eslint/no-explicit-any */
  previousData: Record<string, any>,
  fetchMoreData: Record<string, any>
) {
  const mergedData = merge(previousData, fetchMoreData)

  const repos = mergedData?.organizationDetails?.repositoriesPaged?.repos || []

  const reducedRepos = repos.reduce(
    (acc: SourceControlRepository[], cur: SourceControlRepository) => {
      const sameRepoIdx = acc.findIndex(item => item.id === cur.id)

      if (sameRepoIdx >= 0) {
        acc[sameRepoIdx] = merge(acc[sameRepoIdx], cur)
      } else {
        acc.push(cur)
      }

      return acc
    },
    []
  )

  if (
    reducedRepos.length <= repos.length &&
    mergedData?.organizationDetails?.repositoriesPaged?.repos
  ) {
    mergedData.organizationDetails.repositoriesPaged.repos = reducedRepos
  }

  return mergedData
}
