import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { FiSettings } from "react-icons/fi"
import { OrgDetailsTab } from "../constants.js"
import { TabsNav } from "@modules/ui/components/TabsNav"
import { getPathToOrgDetailsTab } from "@modules/organization/shared/utils"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { usePlatformLimitSummariesForUsageValues } from "@modules/organization/shared/hooks/usePlatformLimitSummariesForUsageValues"
import BuildQueueButton from "@modules/site/details/components/BuildQueueButton"
import { useWorkspaceBuildQueue } from "@modules/organization/builds/hooks/useWorkspaceBuildQueue"
import { WorkspaceBuildQueue } from "@modules/organization/builds/components/WorkspaceBuildQueue"
import { viewBuildQueueTabCss } from "@modules/ui/stylesheets/buildQueue"

const orgTabIcons = {
  [OrgDetailsTab.Settings]: FiSettings,
}

const propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tab: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  organizationId: PropTypes.string.isRequired,
  activeTab: PropTypes.string,
}

function OrganizationDetailsTabsNav({
  tabs,
  activeTab,
  organizationId,
  numberOfSites,
}) {
  const { trackButtonClicked, trackSegment } = useTracker()
  const numOfSites = numberOfSites
  const [usageValues] = usePlatformLimitSummariesForUsageValues(organizationId)
  const numOfMembers = usageValues.USERS

  // gets everything required to provide the Workspace build queue functionality
  const buildQueue = useWorkspaceBuildQueue(organizationId)

  return (
    <React.Fragment>
      <TabsNav>
        <TabsNav.Tabs>
          {tabs.map(({ tab, label }) => {
            const isActiveTab = tab === activeTab
            const Icon = orgTabIcons[tab]
            const TabIcon = Icon ? <Icon css={TabsNav.leftIconStyle} /> : null
            const SiteNumberChip = tab === OrgDetailsTab.Sites &&
              numOfSites > 0 && (
                <TabsNav.Chip data-cy={`org-details-${tab}-tab-sites-total`}>
                  {numOfSites}
                </TabsNav.Chip>
              )

            const MembersNumberChip = tab === OrgDetailsTab.Members &&
              numOfMembers > 0 && <TabsNav.Chip>{numOfMembers}</TabsNav.Chip>

            return (
              <TabsNav.Tab key={tab}>
                {isActiveTab ? (
                  <TabsNav.NoLink>
                    {TabIcon} {label} {SiteNumberChip} {MembersNumberChip}
                  </TabsNav.NoLink>
                ) : (
                  <TabsNav.Link
                    data-cy={`org-details-${tab}-tab-button`}
                    to={getPathToOrgDetailsTab(organizationId, tab)}
                    onClick={() => {
                      trackSegment({
                        type: SegmentEventType.Track,
                        event: `Navigated to workspace details ${tab} tab`,
                        properties: {
                          location: "Workspace details view",
                        },
                      })
                      trackButtonClicked(
                        `Navigated to workspace details ${tab} tab`,
                        {
                          organizationId,
                          uiSource: `Workspace details view`,
                        }
                      )
                    }}
                  >
                    {TabIcon} <TabsNav.Label label={label} />
                    {SiteNumberChip} {MembersNumberChip}
                  </TabsNav.Link>
                )}
                {isActiveTab && (
                  <Helmet>
                    <title>{label}</title>
                  </Helmet>
                )}
              </TabsNav.Tab>
            )
          })}

          <li css={viewBuildQueueTabCss}>
            <BuildQueueButton
              source="Workspace nav (tabs)"
              onClick={buildQueue.onOpen}
            />
          </li>
        </TabsNav.Tabs>
      </TabsNav>

      <WorkspaceBuildQueue
        organizationId={organizationId}
        isOpen={buildQueue.isOpen}
        onClose={buildQueue.onClose}
        originId={buildQueue.originId}
      />
    </React.Fragment>
  )
}

OrganizationDetailsTabsNav.propTypes = propTypes

export default OrganizationDetailsTabsNav
