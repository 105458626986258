import * as React from "react"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { Button, ThemeCss } from "gatsby-interface"
import { MdArrowForward } from "react-icons/md"
import { deploysView as text } from "@modules/locales/default.js"
import { InterpolationWithTheme } from "@emotion/core"

export type BuildQueueButtonProps = {
  onClick?: () => void
  source: string
}

function BuildQueueButton({ onClick, source, ...rest }: BuildQueueButtonProps) {
  const { trackAction, trackSegment } = useTracker()

  if (!onClick) {
    return null
  }

  return (
    <Button
      css={{ whiteSpace: "nowrap" }}
      variant="GHOST"
      onClick={() => {
        trackAction({
          eventType: `TRACK_EVENT`,
          name: `View build queue clicked`,
          uiSource: source,
        })
        trackSegment({
          type: SegmentEventType.Track,
          event: `View build queue clicked`,
          properties: {
            location: source,
          },
        })
        onClick()
      }}
      size="M"
      rightIcon={<MdArrowForward />}
      {...rest}
    >
      {text.actions.viewBuildQueue}
    </Button>
  )
}

export default BuildQueueButton
