import * as React from "react"
import {
  BuildRunnerType,
  PreviewStatus,
  BuildStatus,
} from "@modules/graphql/types"
import { MdArrowForward } from "react-icons/md"
import { FiLayers, FiAlertCircle } from "react-icons/fi"
import { Badge, ThemeCss, LinkButton } from "gatsby-interface"
import { SiteBuildStatus, SiteName } from "./SiteAbstract.parts"
import SiteBranch from "@modules/site/shared/components/SiteBranch"
import { getPreviewStatus } from "@modules/build/shared/utils"
import {
  getPathToSiteDetails,
  getPathToBuildDetails,
} from "@modules/site/details/utils"
import { LighthouseScores } from "@modules/metrics/components/LighthouseScores"
import { sites as text } from "@modules/locales/default.js"
import { idRegex } from "@modules/toolkit/constants"
import { useGetSiteTransferInvitationQuery } from "@modules/site/queries.generated"
import { Spinner } from "gatsby-ui/src/components"
import { useSiteLatestBuilds } from "../hooks/useSiteLatestBuilds"
export type SiteAbstractProps = {
  id: string
  organizationId: string
  name: string
  repositoryId: string
  previewStatus?: PreviewStatus | null
  buildsEnabled?: boolean | null
  previewBuildsEnabled?: boolean | null
  incrementalPreviewsEnabled?: boolean | null
  merlinProductionSourcingEnabled?: boolean | null
  branch: string
  internalLinksEnabled?: boolean
  displayLighthouse?: boolean
  Slot?: React.ComponentType<{ siteId: string }>
  parentOrganizationId?: string
}

export function SiteAbstract({
  id,
  organizationId,
  name: siteName,
  previewStatus,
  buildsEnabled,
  previewBuildsEnabled,
  incrementalPreviewsEnabled,
  branch,
  internalLinksEnabled = true,
  displayLighthouse = true,
  parentOrganizationId,
  merlinProductionSourcingEnabled,
  Slot,
}: SiteAbstractProps) {
  const pathToDetails = getPathToSiteDetails(id, organizationId)

  const siteLatestBuilds = useSiteLatestBuilds(id)
  const latestBuild = siteLatestBuilds.latestBuild
  const latestPreview = siteLatestBuilds.latestPreview
  const statesLoading = siteLatestBuilds.loading
  const statesError = siteLatestBuilds.error

  const pathToLatestBuildDetails =
    latestBuild && getPathToBuildDetails(latestBuild.id, id, organizationId)

  const buildStatus = latestBuild?.buildStatus
  const buildStartedAt = latestBuild?.startedAt
  const buildCreatedAt = latestBuild?.createdAt
  const buildDuration = latestBuild?.duration
  const buildEndedAt = latestBuild?.endedAt
  const latestBuildId = latestBuild?.id
  const isSuccessBuild = buildStatus === BuildStatus.Success

  const dashboardUrl = !internalLinksEnabled
    ? process.env.GATSBY_DASHBOARD_URL
    : ``

  const viewErrorLink = `${dashboardUrl}${pathToLatestBuildDetails}#errors`

  const { data } = useGetSiteTransferInvitationQuery({
    variables: {
      id,
    },
  })

  const siteTransferPending =
    !parentOrganizationId &&
    data?.getSiteTransferInvitation?.id &&
    !data?.getSiteTransferInvitation?.accepted

  const siteTransferred = parentOrganizationId

  return (
    <article css={rootCss}>
      <header css={headerCss}>
        <SiteName
          siteName={siteName}
          internalLinksEnabled={internalLinksEnabled}
          pathToDetails={pathToDetails}
        />

        {branch && <SiteBranch>{branch}</SiteBranch>}

        {statesLoading && <SpinnerLoading />}

        {(siteTransferred || siteTransferPending) && (
          <Badge
            tone={siteTransferPending ? `WARNING` : `NEUTRAL`}
            textVariant="DEFAULT"
            css={!statesLoading && { marginLeft: `auto` }}
          >
            {siteTransferPending
              ? text.labels.transferPending
              : text.labels.siteTransferred}
          </Badge>
        )}
      </header>
      {(latestBuild || latestPreview || statesError) && (
        <div css={statusesCss}>
          {statesError ? (
            <p css={statesErrorCss}>
              <FiAlertCircle /> Something went wrong. Try to reload the page.
            </p>
          ) : (
            <React.Fragment>
              {buildsEnabled && latestBuild && (
                <SiteBuildStatus
                  buildStatus={buildStatus}
                  runnerType={BuildRunnerType.Builds}
                  buildStartedAt={buildStartedAt}
                  buildCreatedAt={buildCreatedAt}
                  buildEndedAt={buildEndedAt}
                  buildDuration={buildDuration}
                  data-testid="build-status"
                  a11yId={`Site--${id}--BuildStatus`}
                />
              )}

              {buildsEnabled && buildStatus === BuildStatus.Error && (
                <LinkButton
                  to={viewErrorLink}
                  variant="SECONDARY"
                  size="S"
                  tone="DANGER"
                  rightIcon={<MdArrowForward />}
                  target="__blank"
                >
                  {text.actions.viewErrors}
                </LinkButton>
              )}

              {previewBuildsEnabled && latestPreview && (
                <SiteBuildStatus
                  buildStatus={
                    (incrementalPreviewsEnabled &&
                      latestPreview?.buildStatus) ||
                    getPreviewStatus(
                      latestPreview?.buildStatus || ``,
                      previewStatus
                    )
                  }
                  runnerType={BuildRunnerType.Preview}
                  data-testid="preview-status"
                  a11yId={`Site--${idRegex}--PreviewStatus`}
                />
              )}

              {merlinProductionSourcingEnabled && (
                <Badge tone="NEUTRAL" textVariant="DEFAULT">
                  <FiLayers css={{ marginRight: "0.5em" }} />
                  Content Hub
                </Badge>
              )}

              {!!Slot && <Slot siteId={id} />}
            </React.Fragment>
          )}
        </div>
      )}

      {displayLighthouse && isSuccessBuild && latestBuild && (
        <div>
          <LighthouseScores
            siteId={id}
            organizationId={organizationId}
            buildId={latestBuildId}
            branch={branch}
          />
        </div>
      )}
    </article>
  )
}

const SpinnerLoading = ({ delay = 1000 }) => {
  const [show, setShow] = React.useState(delay ? false : true)

  React.useEffect(() => {
    if (delay) {
      const showTimeout = setTimeout(() => {
        setShow(true)
        clearTimeout(showTimeout)
      }, delay)

      return () => clearTimeout(showTimeout)
    }
  }, [])

  if (!show) {
    return null
  }

  return (
    <div css={loadingCss}>
      <Spinner css={spinnerCss} />
      Loading
    </div>
  )
}

/* styles */

const rootCss: ThemeCss = theme => ({
  display: `grid`,
  gap: theme.space[3],
  marginBottom: theme.space[2],
})

const headerCss: ThemeCss = theme => ({
  display: `flex`,
  flexWrap: `wrap`,
  alignItems: `center`,

  "& > *": {
    margin: `${theme.space[2]} ${theme.space[5]} ${theme.space[2]} 0`,
  },
})

const statusesCss: ThemeCss = theme => ({
  display: `flex`,
  flexWrap: `wrap`,

  "& > *": {
    margin: `${theme.space[1]} ${theme.space[5]} ${theme.space[1]} 0`,
    lineHeight: 1,
  },
})

const loadingCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[40],
  marginLeft: `auto`,
})

const spinnerCss: ThemeCss = theme => ({
  width: `1.2rem`,
  height: `1.2rem`,
  marginRight: theme.space[2],
})

const statesErrorCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[50],
  display: `flex`,
  alignItems: `center`,

  svg: {
    color: theme.colors.red[40],
    marginRight: theme.space[3],
  },
})
