import { usePlatformLimitSummariesQuery } from "@modules/organization/queries.generated"
import { useOrganizationPlanFieldsFragment } from "@modules/billing/shared/fragments.generated"
import { useWorkspaceDetailsShortcuts } from "@modules/organization/builds/hooks/useWorkspaceDetailsShortcuts"
import {
  MachinePricingTier,
  SubscriptionPlan,
  PlatformLimitSummary,
  PlatformLimitSummaryName,
} from "@modules/graphql/types"
import {
  PlatformLimitLabels,
  PlatformLimitsOrder,
  PlatformRenewableLimits,
} from "../constants"

const TIMEOUT_THRESHOLD = 10000
const POLLING_INTERVAL = 999

export function useWorkspaceFeaturesUsage(
  organizationId: string,
  options?: { renewableOnly: boolean }
) {
  const { renewableOnly } = options || {}
  const org = useOrganizationPlanFieldsFragment(organizationId)

  const plan = org?.data?.billing?.plan
  const isFreePlan = plan?.baseFeatures?.tier === MachinePricingTier.Free
  const nextBillingDate = org?.data?.billing?.nextBillingDate
  const trialStartDate = org?.data?.billing?.trialStart
  const trialEndDate = org?.data?.billing?.trialEnd
  const billingStatus = org?.data?.billing?.status

  const usage = usePlatformLimitSummariesQuery({
    variables: {
      workspaceId: organizationId,
    },
  })

  const workspace = useWorkspaceDetailsShortcuts(organizationId)

  const data =
    usage.data?.platformLimitSummaries
      ?.filter(
        item =>
          isFreePlan || item.name !== PlatformLimitSummaryName.MonthlyBuilds
      )
      .filter(item =>
        renewableOnly &&
        !PlatformRenewableLimits.includes(item.name as PlatformLimitSummaryName)
          ? false
          : true
      )
      .filter(item => {
        const fall22PricingDeprecatedLimits = [
          PlatformLimitSummaryName.CustomDomains,
          PlatformLimitSummaryName.HostingIntegrations,
          PlatformLimitSummaryName.Requests,
        ]

        // return all limits that apply in fall '22 pricing structure,
        return !fall22PricingDeprecatedLimits.includes(
          item.name as PlatformLimitSummaryName
        )
      })
      .filter(item => {
        // disable usage info for legacy plans
        return workspace.isOnLegacyPlan &&
          (item.name === PlatformLimitSummaryName.TotalSiteRoutes ||
            item.name === PlatformLimitSummaryName.MonthlyBuildMinutes)
          ? false
          : true
      })
      .map(item => {
        const data = {
          name: item.name,
          label: PlatformLimitLabels[item.name as PlatformLimitSummaryName],
          limit: item.formattedLimit,
          usage: item.workspace.formattedCount,
          percentage: item.workspace.percentageOfLimit,
          isRenewable: PlatformRenewableLimits.includes(
            item.name as PlatformLimitSummaryName
          )
            ? true
            : false,
          // after successfully added/removed a feature add-on there should be a difference between
          // limits defined in plan and provided by platformLimitsSummaries
          // if that's true we mark the metric as 'Updating ...' and start polling for fresh data
          // see the 'if' statements below
          isRecalculating: compareUsageLimitWithPlanLimit(
            item as PlatformLimitSummary,
            plan as SubscriptionPlan
          ),
        }

        // for Free plans we overwrite the usage calculation provided by platformLimitSummaries
        // https://app.shortcut.com/gatsbyjs/story/55419/force-front-end-to-find-number-of-sites-max-from-plan-and-not-usage-limit
        // todo: after pricingFall2022 launch this hack should be removed
        if (isFreePlan && item.name === PlatformLimitSummaryName.Sites) {
          data.limit = `${workspace.planSitesLimit}`
          data.percentage = (Number(data?.usage) / Number(data?.limit)) * 100
          data.isRecalculating = false
        }

        return data
      })
      // filters out unlimited metrics
      .filter(
        item =>
          item.limit !== `-1` &&
          // sometimes the limits provided by 'platformLimitSummaries' and limits defined in 'plan' are not synchronized
          // for cases like this we do double check to make sure we do not show the users unlimited metrics
          workspace?.baseFeaturesLimits[
            item.name as PlatformLimitSummaryName
          ] !== -1
      ) || []

  // takes care of the list fixed order
  const metrics = data.sort(function (x, y) {
    return (
      PlatformLimitsOrder.findIndex(item => item === x.label) -
      PlatformLimitsOrder.findIndex(item => item === y.label)
    )
  })

  // one metric with 'isRecalculating' property value `true`
  // triggers refetching platformLimitsSummary data
  if (metrics.some(item => item.isRecalculating)) {
    usage.startPolling(POLLING_INTERVAL)

    // in case there is issue with receiving updated usage data, see the following `if` statement
    // it timeouts query pooling after TIMEOUT_THRESHOLD
    const clean = setTimeout(() => {
      usage.stopPolling()
      clearTimeout(clean)
    }, TIMEOUT_THRESHOLD)
  }

  // if there is no one metric with `isRecalculating` property value `true`
  // what means the usage data (platformLimitsSummaries) was successfully updated
  // it stops possible polling of platformLimitsSummary query
  if (metrics.every(item => !item.isRecalculating)) {
    usage.stopPolling()
  }

  return {
    metrics,
    nextBillingDate,
    trialStartDate,
    trialEndDate,
    billingStatus,
    isFreePlan,
    refetch: usage.refetch,
  } as const
}

function compareUsageLimitWithPlanLimit(
  item: PlatformLimitSummary,
  plan: SubscriptionPlan | null | undefined
) {
  switch (item.name) {
    case PlatformLimitSummaryName.Users:
      return compareLimitValues(
        plan?.totalFeatures?.users?.quantity,
        item?.limit
      )
    case PlatformLimitSummaryName.Bandwidth:
      return compareLimitValues(
        plan?.totalFeatures?.bandwidth?.quantity,
        item?.limit
      )
    case PlatformLimitSummaryName.Requests:
      return compareLimitValues(
        plan?.totalFeatures?.requests?.quantity,
        item?.limit
      )
    case PlatformLimitSummaryName.Invocations:
      return compareLimitValues(
        plan?.totalFeatures?.invocations?.quantity,
        item?.limit
      )
    case PlatformLimitSummaryName.Sites:
      return compareLimitValues(plan?.baseFeatures?.sites, item?.limit)
    case PlatformLimitSummaryName.HostingIntegrations:
      return compareLimitValues(
        plan?.baseFeatures?.thirdPartyHostingIntegrations,
        item?.limit
      )
    case PlatformLimitSummaryName.CustomDomains:
      return compareLimitValues(plan?.baseFeatures?.domains, item?.limit)
    case PlatformLimitSummaryName.MonthlyBuildMinutes:
      return compareLimitValues(
        plan?.baseFeatures?.monthlyBuildMinutes?.quantity,
        item?.limit
      )
    case PlatformLimitSummaryName.TotalSiteRoutes:
      return compareLimitValues(
        plan?.baseFeatures?.totalSiteRoutes?.quantity,
        item?.limit
      )
    default:
      return false
  }
}

function compareLimitValues(
  planLimit: number | undefined | null,
  usageLimit: number | undefined
) {
  return (
    typeof planLimit === `number` &&
    typeof usageLimit === `number` &&
    planLimit !== usageLimit
  )
}
