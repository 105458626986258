import * as React from "react"
import Helmet from "react-helmet"
import { navigate } from "gatsby"
import Loading from "@modules/ui/components/Loading"
import { setOrgEnvVars } from "@modules/fullStory"
import {
  EmptyState,
  Spacer,
  EmptyStatePrimaryAction,
  ThemeCss,
  Link,
  Button,
} from "gatsby-interface"
import OrganizationDetailsTabsContainer from "./OrganizationDetailsTabsContainer"
import { StandardSingleColumn } from "@modules/ui/layouts/Containers"
import { organizations as orgText } from "@modules/locales/default.js"
import { MdBlock, MdLockOutline } from "react-icons/md"
import { organizations as organizationsText } from "@modules/locales/default.js"
import { getOrgDetailsTabs } from "@modules/organization/details/utils"
import { useFlags } from "@modules/featureFlags"
import { usePlatformLimitSummariesQuery } from "@modules/organization/queries.generated"
import { getPathToOrgPricing } from "@modules/organization/shared/utils"
import { getPlatformLimitOveragesMessage } from "@modules/organization/shared/utils/getPlatformLimitOveragesMessage"
import { useFilterOutNotTrackedPlatformLimit } from "@modules/organization/shared/hooks/usefilterOutNotTrackedPlatformLimit"
import { PlatformLimitOverageNotification } from "@modules/organization/shared/components/PlatformLimitOverageNotification"
import { StatusPageBanner } from "@modules/status/components/StatusPageBanner"
import { BannersContainer } from "@modules/ui/components/BannersContainer"
import { useOrganizationBaseDetailsQuery } from "@modules/organization/queries.generated"
import { useOrganizationRepositories } from "@modules/organization/list/hooks/useOrganizationRepositories"
import { useDebouncedInput } from "@modules/toolkit/hooks/useDebouncedInput"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { OrganizationHeader } from "@modules/organization/header/components/OrganizationHeader"
import { SubscriptionStatus } from "@modules/graphql/types"
import useNetPromoterSurvey from "@modules/netPromoterSurvey/useNetPromoterSurvey"
import { useCurrentUser } from "@modules/auth"
import { PlanTrialEndedPrompt } from "@modules/organization/shared/components/PlanTrialEndedPrompt"
import { OrganizationPermissions } from "@modules/organization/permissions"
import { SiteTransferNotification } from "@modules/site/transfer/components/SiteTransferNotification"
import { RouteComponentProps } from "@gatsbyjs/reach-router"
import { DisabledDataBuildsBanner } from "./DisabledDataBuildsBanner"
import { useUnblockOrganization } from "@modules/organization/shared/hooks/useUnblockOrganization"
import { format, add } from "date-fns"

const notificationsCss: ThemeCss = theme => ({
  display: `grid`,
  marginTop: theme.space[3],
  marginBottom: theme.space[6],
  rowGap: theme.space[6],
})

type OrganizationDetailsViewProps = RouteComponentProps & {
  organizationId: string
  "*": string
}

export function OrganizationDetailsView({
  organizationId,
  ...rest
}: OrganizationDetailsViewProps) {
  const { flags } = useFlags()
  const { trackSegment } = useTracker()
  const pathToBillingForm = getPathToOrgPricing(organizationId)
  const [searchText, debouncedSearchText, setSearchText] =
    useDebouncedInput(1000)

  const {
    loading: baseDataLoading,
    error: baseDataError,
    data: baseData,
  } = useOrganizationBaseDetailsQuery({
    variables: { id: organizationId },
    fetchPolicy: "cache-and-network",
  })

  const { unblock, unblocking, unblockingError } = useUnblockOrganization(
    baseData?.organizationDetails?.id
  )

  const { data: limitSummariesData, loading: limitSummariesDataLoading } =
    usePlatformLimitSummariesQuery({
      variables: { workspaceId: organizationId },
      fetchPolicy: "cache-and-network",
    })

  const organizationDetails = baseData?.organizationDetails
  const { currentUser } = useCurrentUser()

  const orgIsBlocked = organizationDetails?.blocked
  const userCanUnblockOrg = organizationDetails?.canUnblock
  const orgBlockAt = organizationDetails?.blockAt

  const filterOutNotTrackedPlatformLimit =
    useFilterOutNotTrackedPlatformLimit(organizationId)

  useNetPromoterSurvey(organizationDetails, currentUser)

  React.useEffect(() => {
    if (!organizationDetails || !organizationDetails.name) {
      return
    }

    // Send organization id and name to Segment to track activity on a per
    // workspace basis.
    trackSegment({
      type: SegmentEventType.Group,
      workspaceId: organizationId,
      traits: {
        name: organizationDetails.name,
        buildsTier: organizationDetails?.billing?.plan?.buildsTier || "",
        hostingTier: organizationDetails?.billing?.plan?.hostingTier || "",
        tier: organizationDetails?.billing?.plan?.baseFeatures?.tier || "",
        usersLimit:
          organizationDetails.billing?.plan?.totalFeatures?.users?.quantity?.toString() ||
          "",
        sitesLimit:
          organizationDetails.billing?.plan?.baseFeatures?.sites?.toString() ||
          "",
        inTrial:
          organizationDetails.billing?.status === `TRIALING` ? true : false,
      },
    })
  }, [organizationId, organizationDetails?.name])

  const platformLimitSummaries = (
    limitSummariesData?.platformLimitSummaries || []
  )
    .filter(Boolean)
    .filter(item => filterOutNotTrackedPlatformLimit(item.name))

  const platformLimitOveragesMessage = getPlatformLimitOveragesMessage(
    platformLimitSummaries
  )

  const [
    repositoriesData,
    numberOfSites,
    {
      loading: repositoriesDataLoading,
      error: repositoriesDataError,
      loadMore,
      loadingMore,
    },
  ] = useOrganizationRepositories(organizationId, debouncedSearchText.trim())

  const organizationRepositories = repositoriesData?.repos

  const disabledDataBuildsSites = organizationRepositories?.reduce(
    (
      sites: { siteId: string; organizationId: string; siteName: string }[],
      orgRepo
    ) => {
      if (orgRepo.sites && orgRepo.sites.length > 0) {
        const sitesForNotification = []
        for (const site of orgRepo.sites) {
          if (site && !site.dataBuildsEnabled) {
            sitesForNotification.push({
              siteId: site.id,
              organizationId: organizationId,
              siteName: site.publicName,
            })
          }
        }
        if (sitesForNotification.length) {
          return [...sites, ...sitesForNotification]
        } else {
          return sites
        }
      } else {
        return sites
      }
    },
    []
  )

  if (baseDataLoading || limitSummariesDataLoading || repositoriesDataLoading) {
    return <Loading delay={1000} message={orgText.messages.loadingDetails} />
  }

  if (baseDataError || repositoriesDataError) {
    if (baseDataError?.message.includes(`Not Authorised!`)) {
      return (
        <StandardSingleColumn>
          <Spacer size={12} />

          <EmptyState
            graphic={<MdLockOutline />}
            variant="BORDERED"
            heading={organizationsText.headers.noAccess}
            headingAs="h3"
            text={organizationsText.messages.contactTheOwner}
            primaryAction={
              <EmptyStatePrimaryAction to={`/dashboard/sites`}>
                {organizationsText.actions.backToHomepage}
              </EmptyStatePrimaryAction>
            }
          />
        </StandardSingleColumn>
      )
    }

    navigate(`/dashboard/sites`)
    return null
  }

  const { permissions, billing } = organizationDetails || {}

  const hasAccess =
    permissions && permissions.organization && permissions.organization.read

  if (!hasAccess || !organizationId) {
    navigate(`/dashboard/sites`)
    return null
  }

  setOrgEnvVars(
    organizationDetails?.name,
    billing?.plan?.name,
    billing?.status,
    billing?.trialDaysRemaining,
    permissions?.role
  )

  const planName = billing?.plan?.name
  const tabs = getOrgDetailsTabs(flags, permissions.organization)
  const path = rest[`*`]
  const activeTab = tabs.find(({ tab }) => path.includes(tab))

  if (!organizationDetails || !organizationRepositories) {
    return null
  }

  if (orgIsBlocked) {
    return (
      <main>
        <StandardSingleColumn css={blockedOrgCss}>
          <MdBlock className="icon" />
          <p>
            Gatsby Cloud is being discontinued. <br />
            The <b>{organizationDetails.name}</b> workspace is suspended. <br />
            <Link href="https://www.netlify.com/blog/gatsby-cloud-evolution/?utm_source=gatsby_cloud&utm_medium=banner">
              Learn more
            </Link>
          </p>

          {userCanUnblockOrg && orgBlockAt && (
            <>
              <div>
                <p>
                  For a one-time access extension <br /> until{" "}
                  <b>
                    {format(
                      add(new Date(orgBlockAt), { days: 30 }),
                      `LLLL dd, yyyy`
                    )}
                  </b>{" "}
                  press the button.
                </p>
                {unblockingError && (
                  <div>
                    <p className="error">{unblockingError}</p>
                  </div>
                )}
              </div>

              <Button
                onClick={unblock}
                loading={unblocking}
                disabled={unblocking}
              >
                Extend access
              </Button>
            </>
          )}
        </StandardSingleColumn>
      </main>
    )
  }

  return (
    <main>
      <Helmet
        titleTemplate={`Gatsby Cloud | ${organizationDetails.name} | %s`}
        defaultTitle={`Gatsby Cloud | ${organizationDetails.name}`}
      />
      <StandardSingleColumn>
        <BannersContainer>
          <StatusPageBanner />

          {platformLimitOveragesMessage && (
            <div css={notificationsCss}>
              <PlatformLimitOverageNotification
                orgName={organizationDetails.name}
                message={platformLimitOveragesMessage}
                tier={organizationDetails?.billing?.plan?.baseFeatures?.tier}
                link={pathToBillingForm}
              />
            </div>
          )}

          <SiteTransferNotification />

          {flags.disabledDataBuilds &&
            disabledDataBuildsSites &&
            disabledDataBuildsSites.map(
              ({ siteId, siteName, organizationId }) => (
                <DisabledDataBuildsBanner
                  key={siteId}
                  siteId={siteId}
                  siteName={siteName}
                  organizationId={organizationId}
                />
              )
            )}
        </BannersContainer>

        <OrganizationHeader
          organizationId={organizationDetails.id}
          organizationName={organizationDetails.name}
          planName={planName}
          isTrialing={billing?.status === SubscriptionStatus.Trialing}
          trialDaysRemaining={billing?.trialDaysRemaining}
          activeTabLabel={activeTab?.label}
        />
      </StandardSingleColumn>

      <OrganizationDetailsTabsContainer
        organizationId={organizationId}
        organizationDetails={organizationDetails}
        organizationRepositories={organizationRepositories}
        numberOfSites={numberOfSites}
        loadMore={loadMore}
        loadingMore={loadingMore}
        searchText={searchText}
        debouncedSearchText={debouncedSearchText}
        setSearchText={setSearchText}
        tabs={tabs}
        activeTab={activeTab?.tab}
      />

      <OrganizationPermissions
        id={organizationId}
        resource="billing"
        action="edit"
      >
        {organizationDetails && (
          <PlanTrialEndedPrompt organization={organizationDetails} />
        )}
      </OrganizationPermissions>
    </main>
  )
}

export default OrganizationDetailsView

/* styles */

const blockedOrgCss: ThemeCss = theme => ({
  padding: theme.space[5],
  display: `grid`,
  alignItems: `center`,
  justifyItems: `center`,
  gap: theme.space[3],
  alignContent: `center`,
  justifyContent: `center`,
  minHeight: `calc(100vh - 12rem)`,

  div: {
    marginTop: theme.space[8],
  },

  button: {
    marginTop: theme.space[5],
  },

  ["svg.icon"]: {
    fontSize: theme.fontSizes[9],
    fill: theme.colors.red[60],
    marginBottom: theme.space[5],
  },

  p: {
    fontSize: theme.fontSizes[4],
    textAlign: `center`,
    margin: 0,
  },

  [".error"]: {
    color: theme.colors.red[60],
    fontSize: theme.fontSizes[3],
  },
})
