import * as React from "react"
import { Notification, Link, Spacer } from "gatsby-interface"
import { useWaitingSiteTransferInvitation } from "../hooks/useWaitingSiteTransferInvitation"
import { MdArrowForward } from "react-icons/md"
import { siteTransfer as text } from "@modules/locales/default.js"
import { interpolateMessage } from "@modules/locales"

export function SiteTransferNotification() {
  const invitation = useWaitingSiteTransferInvitation()

  if (!invitation.details) {
    return null
  }

  return (
    <Notification
      content={
        <span>
          <React.Fragment>
            {interpolateMessage<"senderName">(
              text.messages.senderWantsToTransferSite,
              { senderName: invitation?.details?.senderName || `` }
            )}
            <Spacer size={3} direction="horizontal" />
            <Link to={`/dashboard/site-transfer/${invitation.id}`}>
              {text.labels.reviewSiteTransfer} <MdArrowForward />
            </Link>
          </React.Fragment>
        </span>
      }
      variant="SECONDARY"
    />
  )
}
