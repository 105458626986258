import * as React from "react"
import * as Yup from "yup"
import { Button, StyledModalActions } from "gatsby-interface"
import { Form, FormElement } from "@modules/form/components/Form"
import { InputConnectedField } from "gatsby-interface"
import { selfHostedGitConnection as text } from "@modules/locales/default.js"

const ERROR_MESSAGE = `Please make sure your input matches the connection name.`

function createSchema(connectionName: string) {
  return Yup.object().shape({
    connectionNameInput: Yup.string()
      .required(`Required`)
      .test(`nameEquality`, ERROR_MESSAGE, function(value) {
        return value === connectionName
      }),
  })
}

type DeleteGitConnectionFormProps = {
  submitCallback: (id?: string) => void
  cancelCallback: () => void
  vcsConfigId?: string
  connectionName: string
}

export function DeleteGitConnectionForm({
  submitCallback,
  cancelCallback,
  vcsConfigId,
  connectionName,
}: DeleteGitConnectionFormProps) {
  return (
    <Form
      initialValues={{ connectionNameInput: ``, connectionName, vcsConfigId }}
      initialErrors={{ connectionNameInput: ERROR_MESSAGE }}
      validationSchema={createSchema(connectionName)}
      onSubmit={values => {
        submitCallback(values.vcsConfigId)
      }}
    >
      {({ isSubmitting, isValid }) => (
        <FormElement autoComplete="off">
          <InputConnectedField
            name="connectionNameInput"
            label="Git connection name:"
            hint={text.messages.loseConnectionDescription}
            required
            data-cy="delete-connection-name-input"
          />

          <StyledModalActions>
            <Button
              variant="SECONDARY"
              tone="NEUTRAL"
              type="reset"
              onClick={cancelCallback}
              data-cy="delete-connection-modal-cancel-button"
            >
              {text.actions.cancel}
            </Button>
            <Button
              type="submit"
              tone="DANGER"
              data-cy="delete-connection-modal-submit-button"
              loading={isSubmitting}
              loadingLabel="Deleting"
              disabled={!isValid}
            >
              {text.messages.yesDeleteConnection}
            </Button>
          </StyledModalActions>
        </FormElement>
      )}
    </Form>
  )
}
