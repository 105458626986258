import React from "react"
import { Link } from "gatsby"
import { NumberBadge, Button, tabsStyles, dimensions } from "gatsby-interface"

/*

  Note: Because unregular mobile page padding `1.4rem` (the value not exists in theme.space) [1]
  and some subtle relation between page padding vs scrollable container for mobile
  and link paddings for nice looking focus outline there are plenty of hard coded 'space' value
  I believe that in the next iteration, together with fixing the [1] the values could be rewrite
  to use space design tokens.

*/

export function TabsNav(props) {
  return (
    <nav
      css={theme => [
        tabsStyles.tabs(theme),
        {
          paddingTop: `${theme.space[8]}`,
          // bottom border, ref. https://github.com/gatsby-inc/gatsby-interface/blob/68359c0e5fde2f283c57b47e76c71efb0c008c80/src/components/Tabs/Tabs.styles.tsx#L43-L44
          boxShadow: `inset 0 -1px 0 ${theme.colors.blackFade[10]}`,
        },
      ]}
      {...props}
    />
  )
}

TabsNav.Tabs = function (props) {
  return (
    <ul
      css={theme => [
        tabsStyles.tabList(theme),
        {
          // remove bottom border, ref. https://github.com/gatsby-inc/gatsby-interface/blob/68359c0e5fde2f283c57b47e76c71efb0c008c80/src/components/Tabs/Tabs.styles.tsx#L43-L44
          boxShadow: "none",
          paddingLeft: dimensions.pagePadding.mobile,
          paddingRight: dimensions.pagePadding.mobile,
          margin: `0 auto`,
          maxWidth: `80rem`,

          [theme.mediaQueries.tablet]: {
            paddingLeft: dimensions.pagePadding.tablet,
            paddingRight: dimensions.pagePadding.tablet,
          },
        },
      ]}
      {...props}
    />
  )
}

TabsNav.Tab = function ({ isLast, ...rest }) {
  return (
    <li
      css={theme => [
        tabsStyles.tab(theme),
        {
          display: isLast ? `flex` : undefined,
          flexGrow: isLast ? 1 : undefined,
          justifyContent: isLast ? `flex-end` : undefined,
        },
      ]}
      {...rest}
    />
  )
}

TabsNav.Link = function (props) {
  return (
    <Link
      activeClassName="active"
      partiallyActive
      css={theme => [
        tabsStyles.tabButton(theme),
        {
          textDecoration: `none`,
          "&:hover": {
            textDecoration: `none`,
          },
          "&.active": tabsStyles.tabButtonSelected(theme),
        },
      ]}
      {...props}
    />
  )
}

TabsNav.NoLink = function (props) {
  return (
    <span
      css={theme => [
        tabsStyles.tabButton(theme),
        tabsStyles.tabButtonSelected(theme),
      ]}
      {...props}
    />
  )
}

TabsNav.Chip = function (props) {
  return (
    <NumberBadge
      tone="NEUTRAL"
      css={theme => ({
        marginLeft: theme.space[3],
      })}
      {...props}
    />
  )
}

TabsNav.Button = function (props) {
  return (
    <Button
      variant="GHOST"
      size="S"
      type="button"
      css={theme => [tabsStyles.tabButton(theme)]}
      {...props}
    />
  )
}

TabsNav.Label = function (props) {
  const { label } = props
  if (!label) return

  return (
    // the extra <span> and pseudo content are used to minimize
    // layout shifts when the user is switching from tab to tab
    // and the font-weight changes from normal to bold
    // seen at GitHub 🙏
    <span
      css={theme => ({
        position: "relative",
        textAlign: "center",
        "&:after": {
          content: `"${label}"`,
          display: "block",
          fontWeight: theme.fontWeights.semiBold,
          height: 0,
          visibility: "hidden",
        },
      })}
    >
      {label}
    </span>
  )
}

TabsNav.leftIconStyle = theme => ({
  marginRight: theme.space[3],
  color: theme.colors.grey[40],
})

TabsNav.rightIconStyle = theme => ({
  marginLeft: theme.space[3],
  color: theme.colors.grey[40],
})
