import * as React from "react"
import { Modal, useShowSuccessToast } from "gatsby-interface"

import { OnPremisesGitConnectionsDocument } from "@modules/organization/queries.generated"
import { useCreateVcsConfigurationMutation } from "../../mutations.generated"

import { SourceControlProvider } from "@modules/graphql/types"
import { GithubFormValues, GithubModalPanel } from "./GitHubEnterpriseModal"

import { enterpriseConnectionForm as formText } from "@modules/locales/default"
import {
  GitlabFormValues,
  GitlabSelfManagedModal,
} from "./GitlabSelfManagedModal"
type EnterpriseModalProps = {
  isOpen: boolean
  onClose: () => void
  organizationId: string
  source?: SourceControlProvider
}

export function EnterpriseConnectionModal({
  isOpen,
  onClose,
  source = SourceControlProvider.GithubEnterprise,
  organizationId,
}: EnterpriseModalProps) {
  const [error, setError] = React.useState<string | null>()
  const showSuccessToast = useShowSuccessToast()
  const [createVcsConfigurationMutation] = useCreateVcsConfigurationMutation()
  const onSubmit = async (values: GithubFormValues | GitlabFormValues) => {
    const result = await createVcsConfigurationMutation({
      variables: {
        organizationId,
        source,
        vcsConfigSettings: cleanSelfHostedModalInputs(values),
      },
      refetchQueries: [
        {
          query: OnPremisesGitConnectionsDocument,
          variables: {
            workspaceId: organizationId,
          },
        },
      ],
    })
    if (result?.data?.createVcsConfiguration?.success) {
      onClose()
      showSuccessToast(`Added new self-hosted Git connection`)
    } else {
      setError(
        result?.data?.createVcsConfiguration?.message ||
          formText.messages.genericErrorOnSave
      )
    }
  }

  return (
    <React.Fragment>
      <Modal
        aria-label="Add a new self-hosted Git Connection"
        onDismiss={() => {
          onClose()
          setError(null)
        }}
        isOpen={isOpen}
      >
        <SelfManagedModal
          source={source}
          organizationId={organizationId}
          onSubmit={onSubmit}
          onClose={onClose}
          error={error}
        />
      </Modal>
    </React.Fragment>
  )
}

type SelfManagedModalProps = {
  source: SourceControlProvider
  organizationId: string
  onSubmit: (input: GithubFormValues | GitlabFormValues) => void
  onClose: () => void
  error?: string | null
}

function SelfManagedModal({
  source,
  organizationId,
  onSubmit,
  onClose,
  error,
}: SelfManagedModalProps) {
  if (source === SourceControlProvider.GitlabSelfManaged) {
    return (
      <GitlabSelfManagedModal
        onSubmit={onSubmit}
        onClose={onClose}
        error={error}
      />
    )
  }

  return (
    <GithubModalPanel
      organizationId={organizationId}
      onSubmit={onSubmit}
      onClose={onClose}
      error={error}
    />
  )
}

type FormValues = GithubFormValues | GitlabFormValues

function cleanSelfHostedModalInputs(values: FormValues) {
  const newValues = { ...values }
  Object.keys(values).forEach(key => {
    if (key === `instanceUrl`) {
      const url = values[key].trim()
      const instanceUrl = url.endsWith(`/`) ? url.slice(0, -1) : url
      newValues.instanceUrl = instanceUrl
    } else if (key !== `webhookSecret`) {
      const newValue = values[key as keyof FormValues].trim()
      newValues[key as keyof FormValues] = newValue
    }
  })

  return newValues
}
