import { BaseFeatures, FeaturesSummary } from "@modules/graphql/types"

export type GetMetricDataProps = {
  name: `users` | `bandwidth` | `concurrency`
  baseFeatures?: BaseFeatures | null
  additionalFeatures?: FeaturesSummary | null
  quantity?: number | null
  quantityMultiplier?: number
}

export function getMetricValues({
  name,
  baseFeatures,
  additionalFeatures,
  quantity,
}: GetMetricDataProps) {
  const base = baseFeatures?.[name]?.quantity
  const max = baseFeatures?.[name]?.max
  const limit = max && base && max - base
  const limitAdjusted = max && base && max - base - (quantity || 0)
  const unitPrice = additionalFeatures?.[name]?.formattedAmount

  return {
    base,
    max,
    limit,
    limitAdjusted,
    unitPrice,
  }
}

export function formatBandwidthAmount(bandwidth: number) {
  if (bandwidth >= 1000) {
    return `${bandwidth / 1000} TB`
  } else {
    return `${bandwidth} GB`
  }
}
