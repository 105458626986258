import * as React from "react"
import { ThemeCss } from "gatsby-interface"
import { BuildRunnerType, BuildStatus } from "@modules/graphql/types"
import BuildStatusIcon from "@modules/build/shared/components/BuildStatusIcon"
import {
  getBuildStatusVariant,
  getBuildStatusLabels,
  formatRunnerType,
} from "@modules/build/shared/utils"
import { DateTimeDistanceToNow } from "@modules/ui/components/DateTimeDistance"
import BuildCardStatusInfo from "@modules/build/card/components/BuildCardStatusInfo"
import { useTracker, GAEventType } from "@modules/analytics"
import { Link } from "gatsby"
import { sites as text } from "@modules/locales/default.js"
import { interpolateMessage } from "@modules/locales"

export type SiteBuildStatusProps = {
  buildStatus?: BuildStatus | null
  buildStartedAt?: Date
  buildCreatedAt?: Date
  buildEndedAt?: Date
  buildDuration?: number | null
  runnerType: BuildRunnerType
  "data-testid": string
  a11yId: string
}

export function SiteBuildStatus({
  buildStatus,
  buildStartedAt,
  buildCreatedAt,
  buildEndedAt,
  buildDuration,
  runnerType = BuildRunnerType.Preview,
  "data-testid": dataTestId,
  a11yId,
}: SiteBuildStatusProps) {
  const siteBuildStatusLabel =
    BuildRunnerType.Preview === runnerType
      ? getBuildStatusVariant(buildStatus, runnerType).label
      : getBuildStatusLabels(buildStatus).label

  return (
    <div css={rootCss} data-cy={dataTestId} data-testid={dataTestId}>
      <BuildStatusIcon
        a11yId={a11yId}
        buildStatus={buildStatus}
        runnerType={runnerType}
      />
      {runnerType !== BuildRunnerType.Preview ? (
        <span css={labelCss}>
          <BuildCardStatusInfo
            buildStatus={buildStatus}
            createdAt={buildCreatedAt}
            startedAt={buildStartedAt}
            endedAt={buildEndedAt}
            duration={buildDuration}
          />
        </span>
      ) : (
        <span css={labelCss}>
          {formatRunnerType(runnerType, true)}{" "}
          {siteBuildStatusLabel.toLowerCase()}{" "}
          {buildStartedAt && buildStatus && (
            <DateTimeDistanceToNow fromDate={new Date(buildStartedAt)} />
          )}
        </span>
      )}
    </div>
  )
}

export type SiteNameProps = {
  siteName: string
  pathToDetails: string
  internalLinksEnabled?: boolean
}

export function SiteName({
  siteName,
  pathToDetails,
  internalLinksEnabled = true,
  ...rest
}: SiteNameProps) {
  const { trackGoogleAnalytics } = useTracker()

  return (
    <h3 css={nameCss} {...rest}>
      <Link
        aria-label={interpolateMessage<"siteName">(
          text.messages.goToSiteDetails,
          { siteName }
        )}
        to={
          (internalLinksEnabled ? "" : process.env.GATSBY_DASHBOARD_URL) +
          pathToDetails
        }
        onClick={() => {
          trackGoogleAnalytics({
            eventType: GAEventType.Click,
            category: `Product Engagement - Dashboard`,
            label: {
              loc: `in-page`,
              text: `view details`,
            },
          })
        }}
        target={internalLinksEnabled ? undefined : "_blank"}
      >
        {siteName}
      </Link>

      <span css={separatorCss}>›</span>
    </h3>
  )
}

/* styles */

const rootCss: ThemeCss = theme => ({ display: `flex`, alignItems: `center` })

const labelCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  marginLeft: theme.space[3],
  "&:first-letter": {
    textTransform: `uppercase`,
  },
})

const nameCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[2],
  margin: 0,
  marginRight: theme.space[3],
})

const separatorCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[2],
  transform: `translateY(-10%)`,
  color: theme.colors.grey[40],
  marginLeft: theme.space[3],
})
