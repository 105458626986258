import * as React from "react"
import { navigate } from "gatsby"
import { Button, ThemeCss } from "gatsby-interface"
import { MdAdd } from "react-icons/md"
import {
  StandardSingleColumn,
  PageWithTabsContentSection,
} from "@modules/ui/layouts/Containers"
import BillingStatus from "@modules/billing/shared/components/BillingStatus"
import IdleStatus from "@modules/billing/shared/components/IdleStatus"
import OverageStatus from "@modules/billing/shared/components/OverageStatus"
import Searchbar from "@modules/site/shared/components/Searchbar"
import {
  sites as sitesText,
  sitesList as sitesListText,
} from "@modules/locales/default.js"
import {
  Organization,
  OrganizationStatus as OrganizationStatusConstants,
  Site,
  SourceControlRepository,
} from "@modules/graphql/types"
import { useTracker, GAEventType, BigQueryEventType } from "@modules/analytics"
import { OrganizationPermissions } from "@modules/organization/permissions"
import { recordCreateSite } from "@modules/fullStory"
import { getPathToCreateSite } from "@modules/site/create/shared/utils"
import { SitesEmptyState } from "@modules/organization/shared/components/SitesEmptyState"
import { useFlags } from "@modules/featureFlags"
import { PlatformLimitInfoModal } from "./PlatformLimitInfoModal"
import { useOrganizationPlanFeatures } from "@modules/organization/shared/hooks/useOrganizationPlanFeatures"
import { SitesGroupedByRepository } from "../../sites/components/SitesGroupedByRepository"

export const filterRepositories = (repositories = [], criteria = "") => {
  const filterSites = (repo: OrganizationRepository) => ({
    ...repo,
    sites: repo?.sites?.filter(site =>
      site?.publicName?.toLowerCase().includes(criteria.toLocaleLowerCase())
    ),
  })

  const hasSites = (repo: OrganizationRepository) => repo?.sites?.length !== 0

  return repositories.map(filterSites).filter(hasSites)
}

type OrganizationRepository = Pick<
  SourceControlRepository,
  "id" | "name" | "nameWithOwner" | "url" | "provider"
> & {
  sites?: Pick<
    Site,
    | "id"
    | "name"
    | "publicName"
    | "branch"
    | "updatedAt"
    | "previewUrl"
    | "previewStatus"
    | "previewProtection"
    | "repositoryId"
    | "buildsEnabled"
    | "incrementalPreviewsEnabled"
    | "dataBuildsEnabled"
    | "previewBuildsEnabled"
    | "parentOrganizationId"
  >[]
}

type SingleOrganizationSitesListProps = {
  organizationId: string
  organizationDetails: Omit<
    Organization,
    "createdAt" | "updatedAt" | "rteSummary"
  >
  organizationRepositories: OrganizationRepository[]
  loadMore: () => void
  loadingMore: boolean
  searchText: string
  debouncedSearchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  numberOfSites: number
}

function SingleOrganizationSitesList({
  organizationId,
  organizationDetails,
  organizationRepositories,
  loadMore,
  loadingMore,
  searchText,
  debouncedSearchText,
  setSearchText,
  numberOfSites,
}: SingleOrganizationSitesListProps) {
  const { trackBigQuery, trackGoogleAnalytics } = useTracker()
  const [trackedInputEvent, setTrackedInputEvent] = React.useState(false)
  const [isPlatformLimitModalOpen, setIsPlatformLimitModalOpen] =
    React.useState(false)
  const { flags } = useFlags()

  const data = organizationDetails
  const onSearchInputChange = setSearchText

  const { status, billing, permissions, enforceCap } = data

  const numOfSites = numberOfSites

  const orgIsIdle = status === OrganizationStatusConstants.Idle

  const orgIsOverage =
    enforceCap && status === OrganizationStatusConstants.Overage

  const [planFeatures] = useOrganizationPlanFeatures(organizationId)
  const {
    sites: { quantity: planFeatureSitesQuantity },
  } = planFeatures
  const isSiteQuantityReached = Boolean(
    numberOfSites >= Number(planFeatureSitesQuantity) &&
      planFeatureSitesQuantity !== -1
  )

  React.useEffect(() => {
    trackBigQuery({
      eventType: BigQueryEventType.PageViewed,
      uiSource: `Organization Sites`,
    })
  }, [])

  const createSiteNextStepPath = getPathToCreateSite(organizationId)

  return (
    <PageWithTabsContentSection>
      <StandardSingleColumn>
        <div css={divStyles}>
          <BillingStatus
            orgId={organizationId}
            billing={billing}
            showLink={permissions?.billing?.create ?? false}
            uiSource="Organization sites list"
          />
          {orgIsIdle && (
            <IdleStatus
              orgId={organizationId}
              status={status}
              showLink={permissions?.organization?.edit ?? false}
              uiSource="Organization sites list"
            />
          )}
          {orgIsOverage && (
            <OverageStatus
              orgId={organizationId}
              status={status}
              showLink={permissions?.organization?.edit ?? false}
              uiSource="Organization sites list"
            />
          )}
        </div>
        {numOfSites > 0 || searchText || debouncedSearchText ? (
          <React.Fragment>
            <div css={divStyles}>
              <Searchbar
                css={[
                  {
                    width: `50%`,
                    flexGrow: 0,
                  },
                ]}
                ariaLabel={sitesListText.labels.searchSites}
                placeholder={sitesListText.labels.searchSites}
                value={searchText}
                searching={debouncedSearchText !== searchText || loadingMore}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onSearchInputChange(e.target.value)
                  if (!trackedInputEvent) {
                    trackGoogleAnalytics({
                      eventType: GAEventType.Input,
                      category: `Product Engagement - Dashboard`,
                      label: {
                        loc: `search`,
                        text: `search sites`,
                      },
                    })
                    setTrackedInputEvent(true)
                  }
                }}
              />

              <div css={usageCss}>
                {
                  // does not show the usage message if the quantity is unlimited (-1)
                  planFeatureSitesQuantity !== -1
                    ? `${numberOfSites} / ${planFeatureSitesQuantity} sites used`
                    : ``
                }
              </div>

              <OrganizationPermissions
                id={organizationId}
                resource="sites"
                action="create"
              >
                <Button
                  size={`M`}
                  onClick={() => {
                    if (isSiteQuantityReached) {
                      setIsPlatformLimitModalOpen(true)
                    } else {
                      navigate(createSiteNextStepPath)
                      recordCreateSite()
                      trackBigQuery({
                        eventType: BigQueryEventType.ButtonClicked,
                        buttonName: "Create new site",
                        organizationId,
                        uiSource: `Org Site List`,
                      })
                    }
                  }}
                  rightIcon={<MdAdd />}
                  css={addSiteButtonCss}
                >
                  {sitesText.actions.addSite}
                </Button>
              </OrganizationPermissions>
            </div>

            <SitesGroupedByRepository
              organizationId={organizationId}
              repositories={organizationRepositories}
              state={searchText ? `SEARCHING` : `DEFAULT`}
              loadMore={loadMore}
              loadingMore={loadingMore}
              searchText={searchText}
              debouncedSearchText={debouncedSearchText}
            />

            <PlatformLimitInfoModal
              isOpen={isPlatformLimitModalOpen}
              onDismiss={setIsPlatformLimitModalOpen}
              sitesQuantity={Number(planFeatureSitesQuantity)}
              organizationId={organizationId}
            />
          </React.Fragment>
        ) : (
          <SitesEmptyState organizationId={organizationId} />
        )}
      </StandardSingleColumn>
    </PageWithTabsContentSection>
  )
}

export default SingleOrganizationSitesList

/* styles */

const divStyles: ThemeCss = theme => ({
  display: `flex`,
  marginBottom: theme.space[7],
  alignItems: `center`,

  "&:last-of-type, &:empty": {
    marginBottom: `0`,
  },
})

const usageCss: ThemeCss = theme => ({
  marginLeft: `auto`,
  color: theme.colors.grey[60],
  fontSize: theme.fontSizes[1],
})

const addSiteButtonCss: ThemeCss = theme => ({
  flexShrink: 0,

  [theme.mediaQueries.tablet]: {
    marginLeft: theme.space[4],
  },
})
