import * as React from "react"
import {
  MdMoreVert,
  MdDeleteForever,
  MdAssignmentInd,
  MdEdit,
} from "react-icons/md"
import { Modal, ModalCard } from "gatsby-interface"
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuPopover,
  DropdownMenuItemsLowLevel,
  DropdownMenuItem,
} from "gatsby-interface"
import { positionRight } from "@reach/popover"
import {
  RemoveOrgMemberModal,
  TransferOrgOwnershipModal,
  ModifyMemberPanelModal,
} from "./MemberActionModals"
import { manageContributors as text } from "@modules/locales/default.js"
import useIsOrganizationOwner from "@modules/organization/shared/hooks/useIsOrganizationOwner"
import useIsOrganizationAdmin from "@modules/organization/shared/hooks/useIsOrganizationAdmin"
import { Contributor as ContributorType } from "@modules/graphql/types"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { interpolateMessage } from "@modules/locales"

export type RemoveMemberModalProps = {
  member: ContributorType
  resourceId: string
  isOpen: boolean
  onClose: () => void
  recalculateUsage?: () => void
}

function RemoveMemberModal({
  member,
  resourceId,
  isOpen,
  onClose,
  recalculateUsage,
}: RemoveMemberModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      aria-label={text.headers.removeMember}
      onDismiss={onClose}
      type="error"
    >
      <ModalCard>
        <RemoveOrgMemberModal
          contributorId={member.id}
          email={member.email}
          organizationId={resourceId}
          closeModal={onClose}
          recalculateUsage={recalculateUsage}
        />
      </ModalCard>
    </Modal>
  )
}

export type TransferOwnershipModalProps = {
  memberId: string
  organizationId: string
  email: string
  isOpen: boolean
  onClose: () => void
}

function TransferOwnershipModal({
  memberId,
  organizationId,
  email,
  isOpen,
  onClose,
}: TransferOwnershipModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      aria-label={text.messages.transferOwnership}
      onDismiss={onClose}
      type="error"
    >
      <ModalCard>
        {isOpen && (
          <TransferOrgOwnershipModal
            contributorId={memberId}
            email={email}
            organizationId={organizationId}
            closeModal={onClose}
          />
        )}
      </ModalCard>
    </Modal>
  )
}

export type MemberActionsDropdownProps = {
  member: ContributorType
  resourceId: string
  recalculateUsage?: () => void
}

export function MemberActionsDropdown({
  member,
  resourceId,
  recalculateUsage,
}: MemberActionsDropdownProps) {
  const { trackSegment, trackAction } = useTracker()
  const [isRemoveModalOpened, setIsRemoveModalOpened] = React.useState(false)
  const [
    isTransferOwnerModalOpened,
    setIsTransferOwnerModalOpened,
  ] = React.useState(false)
  const [
    isModifyMemberModalOpened,
    setIsModifyMemberModalOpened,
  ] = React.useState(false)

  const actions = []
  const isOrganizationOwner = useIsOrganizationOwner(resourceId)
  const isOrganizationAdmin = useIsOrganizationAdmin(resourceId)

  if (isOrganizationOwner) {
    actions.push(
      <DropdownMenuItem
        key="transfer"
        onSelect={() => {
          trackAction({
            eventType: `TRACK_EVENT`,
            name: `Removed additional member field`,
            uiSource: `Members tab - "Manage Member" dropdown`,
          })
          trackSegment({
            type: SegmentEventType.Track,
            event: `Clicked to open transfer ownership modal`,
            properties: {
              location: `Members tab - "Manage Member" dropdown`,
            },
          })
          setIsTransferOwnerModalOpened(true)
        }}
        Icon={MdAssignmentInd}
      >
        {text.actions.transferOwnership}
      </DropdownMenuItem>
    )
  }

  if (isOrganizationOwner || isOrganizationAdmin) {
    actions.push(
      <DropdownMenuItem
        key="modify"
        onSelect={() => {
          trackAction({
            eventType: `TRACK_EVENT`,
            name: `Clicked to open modify member modal`,
            uiSource: `Members tab - "Manage Member" dropdown`,
          })
          trackSegment({
            type: SegmentEventType.Track,
            event: `Clicked to open modify member modal`,
            properties: {
              location: `Members tab - "Manage Member" dropdown`,
            },
          })
          setIsModifyMemberModalOpened(true)
        }}
        Icon={MdEdit}
      >
        {text.actions.modifyMember}
      </DropdownMenuItem>
    )
  }

  actions.push(
    <DropdownMenuItem
      key="remove"
      onSelect={() => {
        trackAction({
          eventType: `TRACK_EVENT`,
          name: `Clicked to open remove member modal`,
          uiSource: `Members tab - "Manage Member" dropdown`,
        })
        trackSegment({
          type: SegmentEventType.Track,
          event: `Clicked to open remove member modal`,
          properties: {
            location: `Members tab - "Manage Member" dropdown`,
          },
        })
        setIsRemoveModalOpened(true)
      }}
      Icon={MdDeleteForever}
      tone="CRITICAL"
    >
      {text.actions.removeMember}
    </DropdownMenuItem>
  )

  return (
    <React.Fragment>
      <DropdownMenu>
        <DropdownMenuButton
          css={{
            background: `none`,
            border: `none`,
            // to align with role text
            marginTop: `.3rem`,
            width: `3rem`,
          }}
          aria-label={interpolateMessage<"nameOrEmail">(
            text.actions.manageMember,
            { nameOrEmail: member.name ?? member.email }
          )}
          data-testid="member-button"
        >
          <MdMoreVert aria-hidden />
        </DropdownMenuButton>
        <DropdownMenuPopover position={positionRight}>
          <DropdownMenuItemsLowLevel size="MAX_CONTENT">
            {actions}
          </DropdownMenuItemsLowLevel>
        </DropdownMenuPopover>
      </DropdownMenu>

      <ModifyMemberPanelModal
        member={member}
        organizationId={resourceId}
        isOpen={isModifyMemberModalOpened}
        closeModal={() => setIsModifyMemberModalOpened(false)}
      />

      <RemoveMemberModal
        member={member}
        resourceId={resourceId}
        isOpen={isRemoveModalOpened}
        onClose={() => setIsRemoveModalOpened(false)}
        recalculateUsage={recalculateUsage}
      />

      <TransferOwnershipModal
        isOpen={isTransferOwnerModalOpened}
        onClose={() => setIsTransferOwnerModalOpened(false)}
        memberId={member.id}
        email={member.email}
        organizationId={resourceId}
      />
    </React.Fragment>
  )
}
