import {
  CloudResource,
  CloudResourceType,
  CloudRole,
  Contributor,
  ContributorRole,
} from "@modules/graphql/types"

export type AllowedMemberRole =
  | CloudRole.Reader
  | CloudRole.Contributor
  | CloudRole.Owner
  | CloudRole.Admin

export function getContributorRoleByAllowedRole(
  allowedRole: AllowedMemberRole
): ContributorRole {
  switch (allowedRole) {
    case CloudRole.Owner:
      return ContributorRole.Owner
    case CloudRole.Contributor:
      return ContributorRole.Editor
    case CloudRole.Reader:
      return ContributorRole.Viewer
    case CloudRole.Admin:
      return ContributorRole.Admin
    default:
      throw new Error(
        `Unable to map contributor role ${allowedRole} to an AllowedMemberRole`
      )
  }
}

export function getAllowedRoleByContributorRole(
  contributorRole: ContributorRole
): AllowedMemberRole {
  switch (contributorRole) {
    case ContributorRole.Owner:
      return CloudRole.Owner
    case ContributorRole.Editor:
      return CloudRole.Contributor
    case ContributorRole.Viewer:
      return CloudRole.Reader
    case ContributorRole.Admin:
      return CloudRole.Admin
    default:
      throw new Error(
        `Unable to map contributor role ${contributorRole} to an AllowedMemberRole`
      )
  }
}

/**
 * This function maps the member fields we display in UI ("role", "allow access to all sites" and "site access")
 * to permissions resources for backend consumption.
 *
 * There are two main scenarios possible:
 *  - a member has access to all sites in a workspace
 *  - a member has access only to some sites in a workspace
 */
export function memberFieldsToCloudResources(
  organizationId: string,
  memberFields: Pick<MemberFormValues, "role" | "accessAllSites" | "sites">
): CloudResource[] {
  const resources: CloudResource[] = []

  if (memberFields.accessAllSites || memberFields.role === CloudRole.Owner) {
    /**
     * If the member should have access to all sites in a workspace,
     * we only need a permission for ORGANIZATION resource type with the selected role
     */
    resources.push({
      resourceType: CloudResourceType.Organization,
      resourceId: organizationId,
      role: memberFields.role,
    })
  } else {
    /**
     * If the member should have access only to some sites in a workspace,
     * we need a permission for ORGANIZATION resource type with the _limited_ version of the selected role
     * (e.g. LIMITED_READER instead of READER)
     *
     * AND
     *
     * a permission for each SITE resource type with the selected role (e.g. READER)
     */
    const limitedRole = getLimitedRole(memberFields.role)

    resources.push({
      resourceType: CloudResourceType.Organization,
      resourceId: organizationId,
      role: limitedRole,
    })
    for (const siteId of memberFields.sites) {
      resources.push({
        resourceType: CloudResourceType.Site,
        resourceId: siteId,
        role: memberFields.role,
      })
    }
  }

  return resources
}

export type MemberFormValues = {
  email: string
  role: AllowedMemberRole
  accessAllSites: boolean
  sites: string[]
}

export function memberToMemberFields(member: Contributor): MemberFormValues {
  const memberResources = member.resources ?? []

  let memberOrganizationRole: CloudRole | null = null
  const memberSites: string[] = []

  for (const resource of memberResources) {
    if (resource.resourceType === CloudResourceType.Organization) {
      memberOrganizationRole = resource.role
    }
    if (resource.resourceType === CloudResourceType.Site) {
      memberSites.push(resource.resourceId)
    }
  }

  const hasLimitedAccess =
    memberOrganizationRole === CloudRole.LimitedContributor ||
    memberOrganizationRole === CloudRole.LimitedReader

  return {
    email: member.email,
    role: getAllowedRoleByContributorRole(member.contributorRole),
    accessAllSites: !hasLimitedAccess,
    sites: memberSites,
  }
}

function getLimitedRole(role: AllowedMemberRole): CloudRole {
  if (role === CloudRole.Contributor) {
    return CloudRole.LimitedContributor
  }
  if (role === CloudRole.Reader) {
    return CloudRole.LimitedReader
  }
  throw new Error(`No limited variant for Cloud role ${role}`)
}
