import * as React from "react"
import { useSiteLatestBuildsQuery } from "@modules/site/shared/queries.generated"
import { useSiteChangedSubscription } from "@modules/site/shared/queries.generated"

export function useSiteLatestBuilds(siteId: string) {
  const siteLatestBuilds = useSiteLatestBuildsQuery({
    variables: {
      id: siteId,
    },
  })

  const data = siteLatestBuilds.data
  const latestBuild = data?.siteDetails?.latestBuild
  const latestPreview = data?.siteDetails?.latestPreview
  const loading = siteLatestBuilds.loading
  const error = siteLatestBuilds.error?.message

  useSiteChangedSubscription({
    variables: { id: siteId },
  })

  return {
    latestBuild,
    latestPreview,
    loading,
    error,
  }
}
