import * as React from "react"
import {
  useDeleteContributorFromOrganizationMutation,
  DeleteContributorFromOrganizationMutation,
} from "@modules/organization/mutations.generated"
import {
  useShowSuccessToast,
  Modal,
  ModalCard,
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledModalActions,
  Button,
  Text,
} from "gatsby-interface"
import { ModalMessage, DefaultError } from "@modules/modal"
import {
  manageContributors as text,
  ui as uiText,
} from "@modules/locales/default.js"
import { MutationUpdaterFn } from "apollo-client"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { FormattedMessage, commonMessageTags } from "@modules/locales"
import { StyledForm } from "@modules/ui/components/Primitives"
import { useFlags } from "@modules/featureFlags"
import {
  WorkspaceMembersSimplifiedDocument,
  WorkspaceMembersSimplifiedQuery,
} from "@modules/organization/contributors/queries.generated"

import { LIMIT } from "@modules/organization/contributors/hooks/useWorkspaceMembers"

export type RemoveOrgMemberModalProps = {
  closeModal: () => void
  contributorId: string
  organizationId: string
  email: string
  recalculateUsage?: () => void
}

const updateOrgContributorsCacheOnRemove = ({
  organizationId,
  contributorId,
}: {
  organizationId: string
  contributorId: string
  flags: Record<string, boolean>
}): MutationUpdaterFn<DeleteContributorFromOrganizationMutation> => {
  return cache => {
    const queryInfo = {
      query: WorkspaceMembersSimplifiedDocument,
      variables: {
        workspaceId: organizationId,
        limit: LIMIT,
        offset: 0,
      },
    }

    const membersFromCache =
      cache.readQuery<WorkspaceMembersSimplifiedQuery>(queryInfo)

    const updatedMembers =
      membersFromCache?.workspaceMembersSimplified?.members?.filter(
        ({ id }) => id !== contributorId
      )

    cache.writeQuery({
      ...queryInfo,
      data: {
        workspaceMembersSimplified: { members: updatedMembers },
      },
    })
  }
}

function RemoveOrgMemberModal({
  closeModal,
  email,
  organizationId,
  contributorId,
  recalculateUsage,
}: RemoveOrgMemberModalProps) {
  const { flags } = useFlags()
  const { trackSegment, trackAction } = useTracker()
  const showSuccessToast = useShowSuccessToast()
  const [mutationError, setError] = React.useState(null)
  const [mutate, { loading }] = useDeleteContributorFromOrganizationMutation()

  return (
    <StyledModal variant="WARNING">
      <StyledModalHeader onCloseButtonClick={closeModal}>
        {text.headers.removeMemberQuestion}
      </StyledModalHeader>
      <StyledModalBody>
        <ModalMessage>
          <Text>
            <FormattedMessage<"email", "strong">
              message={text.messages.removeMemberConf}
              values={{ email }}
              tags={commonMessageTags}
            />
          </Text>
          <Text css={{ marginBottom: 0 }}>
            {text.messages.removeMemberEffects}
          </Text>
        </ModalMessage>
        <StyledForm
          onSubmit={e => {
            e.preventDefault()
            trackAction({
              eventType: `TRACK_EVENT`,
              name: `Removed member from workspace`,
              uiSource: `Members tab - "Remove Member" modal`,
            })
            trackSegment({
              type: SegmentEventType.Track,
              event: `Remomved member from workspace`,
              properties: {
                location: `Members tab - "Remove Member" modal`,
              },
            })
            mutate({
              variables: {
                organizationId,
                contributorId,
              },
              update: updateOrgContributorsCacheOnRemove({
                organizationId,
                contributorId,
                flags,
              }),
              optimisticResponse: {
                deleteContributorFromOrganization: {
                  success: true,
                },
              },
            })
              .then(() => {
                closeModal()
                showSuccessToast(text.messages.memberRemoved)
                recalculateUsage && recalculateUsage()
              })
              .catch(err => setError(err.message))
          }}
        >
          <StyledModalActions>
            <Button
              variant="SECONDARY"
              tone="NEUTRAL"
              type="button"
              onClick={() => {
                trackAction({
                  eventType: `TRACK_EVENT`,
                  name: `Cancelled removing member`,
                  uiSource: `Members tab - "Remove Member" modal`,
                })
                trackSegment({
                  type: SegmentEventType.Track,
                  event: `Cancelled removing member`,
                  properties: {
                    location: `Members tab - "Remove Members" modal`,
                  },
                })
                closeModal()
              }}
            >
              {uiText.actions.cancel}
            </Button>
            <Button
              loading={loading}
              variant="PRIMARY"
              tone="DANGER"
              type="submit"
            >
              {text.actions.remove}
            </Button>
          </StyledModalActions>
        </StyledForm>
      </StyledModalBody>

      <Modal
        data-testid="remove-contributor-organization-dialog-error"
        isOpen={Boolean(mutationError)}
        aria-label={`Something went wrong.`}
        onDismiss={() => setError(null)}
        type="error"
      >
        <ModalCard>
          <DefaultError
            errMsg={mutationError || ``}
            closeModal={() => setError(null)}
          />
        </ModalCard>
      </Modal>
    </StyledModal>
  )
}

export { RemoveOrgMemberModal }
