// THIS IS A GENERATED FILE
import * as Types from '@gatsbyjs/graphql/types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';


export type UpdateOrganizationNameMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  organizationId: Types.Scalars['UUID'];
}>;


export type UpdateOrganizationNameMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationName?: Types.Maybe<(
    { __typename?: 'MutationResult' }
    & Pick<Types.MutationResult, 'success' | 'message'>
  )> }
);

export type SetOrganizationActiveMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type SetOrganizationActiveMutation = (
  { __typename?: 'Mutation' }
  & { setOrganizationActive?: Types.Maybe<(
    { __typename?: 'MutationResult' }
    & Pick<Types.MutationResult, 'success' | 'message' | 'errorType'>
  )> }
);

export type VcsHealthChecksForWorkspaceQueryVariables = Types.Exact<{
  workspaceId: Types.Scalars['UUID'];
}>;


export type VcsHealthChecksForWorkspaceQuery = (
  { __typename?: 'Query' }
  & { vcsHealthChecksForWorkspace?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'VcsHealthCheck' }
    & Pick<Types.VcsHealthCheck, 'id' | 'name' | 'source' | 'status' | 'vcsConfigId'>
  )>>> }
);


export const UpdateOrganizationNameDocument = gql`
    mutation UpdateOrganizationName($name: String!, $organizationId: UUID!) {
  updateOrganizationName(name: $name, organizationId: $organizationId) {
    success
    message
  }
}
    `;
export type UpdateOrganizationNameMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationNameMutation, UpdateOrganizationNameMutationVariables>;

/**
 * __useUpdateOrganizationNameMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationNameMutation, { data, loading, error }] = useUpdateOrganizationNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUpdateOrganizationNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationNameMutation, UpdateOrganizationNameMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationNameMutation, UpdateOrganizationNameMutationVariables>(UpdateOrganizationNameDocument, baseOptions);
      }
export type UpdateOrganizationNameMutationHookResult = ReturnType<typeof useUpdateOrganizationNameMutation>;
export type UpdateOrganizationNameMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationNameMutation>;
export type UpdateOrganizationNameMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationNameMutation, UpdateOrganizationNameMutationVariables>;
export const SetOrganizationActiveDocument = gql`
    mutation SetOrganizationActive($id: UUID!) {
  setOrganizationActive(id: $id) {
    success
    message
    errorType
  }
}
    `;
export type SetOrganizationActiveMutationFn = ApolloReactCommon.MutationFunction<SetOrganizationActiveMutation, SetOrganizationActiveMutationVariables>;

/**
 * __useSetOrganizationActiveMutation__
 *
 * To run a mutation, you first call `useSetOrganizationActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrganizationActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrganizationActiveMutation, { data, loading, error }] = useSetOrganizationActiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetOrganizationActiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetOrganizationActiveMutation, SetOrganizationActiveMutationVariables>) {
        return ApolloReactHooks.useMutation<SetOrganizationActiveMutation, SetOrganizationActiveMutationVariables>(SetOrganizationActiveDocument, baseOptions);
      }
export type SetOrganizationActiveMutationHookResult = ReturnType<typeof useSetOrganizationActiveMutation>;
export type SetOrganizationActiveMutationResult = ApolloReactCommon.MutationResult<SetOrganizationActiveMutation>;
export type SetOrganizationActiveMutationOptions = ApolloReactCommon.BaseMutationOptions<SetOrganizationActiveMutation, SetOrganizationActiveMutationVariables>;
export const VcsHealthChecksForWorkspaceDocument = gql`
    query VCSHealthChecksForWorkspace($workspaceId: UUID!) {
  vcsHealthChecksForWorkspace(workspaceId: $workspaceId) {
    id
    name
    source
    status
    vcsConfigId
  }
}
    `;

/**
 * __useVcsHealthChecksForWorkspaceQuery__
 *
 * To run a query within a React component, call `useVcsHealthChecksForWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useVcsHealthChecksForWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVcsHealthChecksForWorkspaceQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useVcsHealthChecksForWorkspaceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VcsHealthChecksForWorkspaceQuery, VcsHealthChecksForWorkspaceQueryVariables>) {
        return ApolloReactHooks.useQuery<VcsHealthChecksForWorkspaceQuery, VcsHealthChecksForWorkspaceQueryVariables>(VcsHealthChecksForWorkspaceDocument, baseOptions);
      }
export function useVcsHealthChecksForWorkspaceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VcsHealthChecksForWorkspaceQuery, VcsHealthChecksForWorkspaceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VcsHealthChecksForWorkspaceQuery, VcsHealthChecksForWorkspaceQueryVariables>(VcsHealthChecksForWorkspaceDocument, baseOptions);
        }
export type VcsHealthChecksForWorkspaceQueryHookResult = ReturnType<typeof useVcsHealthChecksForWorkspaceQuery>;
export type VcsHealthChecksForWorkspaceLazyQueryHookResult = ReturnType<typeof useVcsHealthChecksForWorkspaceLazyQuery>;
export type VcsHealthChecksForWorkspaceQueryResult = ApolloReactCommon.QueryResult<VcsHealthChecksForWorkspaceQuery, VcsHealthChecksForWorkspaceQueryVariables>;
