import * as React from "react"
import { useOrganizationPlanFeaturesQuery } from "@modules/organization/queries.generated"
import { usePlatformLimitSummariesQuery } from "@modules/organization/queries.generated"

const TIMEOUT_THRESHOLD = 5000
const POLLING_INTERVAL = 999

export type UsageData = {
  base: number | null | undefined
  all: number | null | undefined
  max: number | null | undefined
  used: number | undefined
  isExhausted: boolean | 0
  isOccupied: boolean | 0
  isUnlimited: boolean
  message: React.ReactNode
  recalculate: () => void
  isRecalculating: boolean
}

export function useMembersUsage(organizationId: string): UsageData {
  const [isRecalculating, setIsRecalculating] = React.useState(false)
  const [message, setMessage] = React.useState<React.ReactNode>(``)

  const featureInPlan = useOrganizationPlanFeaturesQuery({
    variables: { id: organizationId },
  })

  // gets available slots of the plan without addons
  const base =
    featureInPlan.data?.organizationDetails?.billing?.plan?.baseFeatures?.users
      ?.quantity

  // gets all available slots of the plan
  const all =
    featureInPlan.data?.organizationDetails?.billing?.plan?.totalFeatures?.users
      ?.quantity

  const max =
    featureInPlan.data?.organizationDetails?.billing?.plan?.baseFeatures?.users
      ?.max

  const platformUsage = usePlatformLimitSummariesQuery({
    variables: { workspaceId: organizationId },
  })

  const featureUsage = platformUsage.data?.platformLimitSummaries?.find(
    item => item.name === `USERS`
  )

  // gets all ocupied slots at the moment
  const used = featureUsage?.workspace?.count

  React.useEffect(() => {
    // when the updated usage data comes, it updates the message
    setMessage(getMessage())
    // stops polling
    platformUsage.stopPolling()
    // and terminate recalculating state
    setIsRecalculating(false)
  }, [used])

  // every successful custom domain action, such as adding a new custom domain
  // or removing existing one triggers the recalculate method
  const recalculate = () => {
    // starts polling platformLimitsSummaries data expecting new values (see useEffect above)
    platformUsage.startPolling(POLLING_INTERVAL)
    // and initiate the recalculating state
    setIsRecalculating(true)

    // in cases when the plaatformLimitsSummaries data does not change, e.g. when the secondary domain was removed
    // it manually stop polling and terminating recalculation state after TIMEOUT period (5000ms)
    const clean = setTimeout(() => {
      platformUsage.stopPolling()
      setIsRecalculating(false)
      clearTimeout(clean)
    }, TIMEOUT_THRESHOLD)
  }

  const getMessage = () =>
    typeof all === `number` && typeof used === `number` && all
      ? `${used} / ${all} member${all > 1 ? `s` : ``} used`
      : ``

  // all available steats are taken, but there is still an option to upgrade with add-ons
  const isOccupied = () =>
    typeof all === `number` &&
    typeof used === `number` &&
    all &&
    used &&
    all - used <= 0

  // all add-ons used, the only option to upgrade is to upgrade the plan
  const isExhausted = () =>
    typeof max === `number` &&
    typeof used === `number` &&
    max &&
    used &&
    max - used <= 0

  const isUnlimited = () => all === -1 || max === -1

  return {
    base,
    all,
    max,
    used,
    isExhausted: isExhausted(),
    isOccupied: isOccupied(),
    isUnlimited: isUnlimited(),
    message,
    recalculate,
    isRecalculating,
  }
}
